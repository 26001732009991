import React, { useEffect, useState } from "react";
import { Route, Switch, useLocation } from "react-router-dom";
// import ScrollRestoration from "react-scroll-restoration";

// scss
import "./index.scss";
// components

import Footer from "./components/Footer";
import Header from "./components/Header/Header";

// pages
import HomePage from "./pages/Homepage";
import Products from "./pages/Products";
import ProductsCategory from "./pages/ProductsCategory";
import ProductsSubCategory from "./pages/ProductsSubCategory";

import ProductDetail from "./pages/ProductDetail";

import About from "./pages/About";
import ChangePassword from "./pages/Account/ChangePassword";
import Favorites from "./pages/Account/Favorites";
import OrderHistory from "./pages/Account/OrderHistory";
import PersonalInfo from "./pages/Account/PersonalInfo";
import Code from "./pages/Authentification/Code/Code";
import CodeRegister from "./pages/Authentification/Code/CodeRegister";
import Forget from "./pages/Authentification/Forget";
import Login from "./pages/Authentification/Login";
import Register from "./pages/Authentification/Register";
import Cart from "./pages/Cart";
import Compare from "./pages/Compare";
import News from "./pages/News";
import Bonus from "./pages/Support/Bonus";
import Credit from "./pages/Support/Credit";
import Delivery from "./pages/Support/Delivery";
import Faq from "./pages/Support/Faq";
import Privacy from "./pages/Support/Privacy";
import SalesReturn from "./pages/Support/SalesReturn";
import Terms from "./pages/Support/Terms";

import { useDispatch } from "react-redux";
import ApiService from "./api/axios";
import { setTranslates } from "./helpers/helpers";
import Address from "./pages/Account/Address";
import ResetPassword from "./pages/Authentification/ResetPassword";
import Contact from "./pages/Contact";
import Error from "./pages/Error";
import NewsDetail from "./pages/NewsDetail";
import PaymentTerms from "./pages/Support/PaymentTerms";
import { initialCartData } from "./store/cartSlice";

function App() {
  const [allSettings, setAllSettings] = useState({});
  const dispatch = useDispatch();

  const location = useLocation();

  useEffect(() => {
    document.body.classList.remove("body-overflow");
  }, [location]);

  useEffect(() => {
    if (!localStorage.guest_uid) {
      ApiService.get("cart/guest/uid/generate").then((resp) => {
        localStorage.setItem("guest_uid", resp.data.uid);
      });
    }

    ApiService.get("helpers/settings").then((resp) => {
      setAllSettings(resp.data.data);
    });
    setTranslates();
    if (!sessionStorage.locale) {
      sessionStorage.setItem("locale", "az");
    }

    if (localStorage.token) {
      ApiService.get("/cart/user/list").then((res) => {
        dispatch(initialCartData(res.data.data));
      });
    } else {
      ApiService.get("/cart/guest/list", {
        params: { guest_uid: localStorage.guest_uid },
      }).then((res) => {
        dispatch(initialCartData(res.data.data));
      });
    }
  }, []);
  const requireLogin = () => {
    let logged_in = false;
    if (localStorage.token) {
      logged_in = true;
    }
    if (!logged_in) {
      this.props.history.push("/login");
    }
  };
  return (
    <div className="App">
      <Header settings={allSettings} />
      {/* <ScrollRestoration /> */}

      <Switch>
        <Route exact path="/" component={HomePage} />
        <Route exact path="/products" component={Products} />
        <Route
          exact
          path="/products/category/:category"
          component={ProductsCategory}
        />

        <Route
          exact
          path="/products/category/:category/:subcategory"
          component={ProductsSubCategory}
        />
        <Route
          exact
          path="/products/:subcategory/:alias"
          component={ProductDetail}
        />

        <Route exact path="/about" component={About} />
        <Route exact path="/login" component={Login} />
        <Route exact path="/register" component={Register} />
        <Route exact path="/forget-password" component={Forget} />
        <Route exact path="/code" component={Code} />
        <Route exact path="/code-register" component={CodeRegister} />
        <Route exact path="/reset-password" component={ResetPassword} />
        <Route
          exact
          path="/account/personal-info"
          onenter={() => requireLogin()}
          component={PersonalInfo}
        />
        <Route
          exact
          path="/account/favorites"
          onenter={() => requireLogin()}
          component={Favorites}
        />
        <Route
          exact
          path="/account/order-history"
          onenter={() => requireLogin()}
          component={OrderHistory}
        />
        <Route
          exact
          path="/account/change-password"
          onenter={() => requireLogin()}
          component={ChangePassword}
        />
        <Route
          exact
          path="/account/address"
          onenter={() => requireLogin()}
          component={Address}
        />
        <Route exact path="/cart" component={Cart} />
        <Route exact path="/compare" component={Compare} />
        <Route exact path="/blog" component={News} />
        <Route exact path="/support/delivery" component={Delivery} />
        <Route exact path="/support/faq" component={Faq} />
        <Route exact path="/support/bonus" component={Bonus} />
        <Route exact path="/support/credit-cards" component={Credit} />
        <Route exact path="/support/sales-return" component={SalesReturn} />
        <Route exact path="/support/terms-and-conditions" component={Terms} />
        <Route exact path="/support/privacy-policy" component={Privacy} />
        <Route exact path="/support/payment-terms" component={PaymentTerms} />
        <Route
          exact
          path="/support/contact"
          settings={allSettings}
          component={Contact}
          
        />
        <Route exact path="/blog/:alias" component={NewsDetail} />
        <Route exact path="*" component={Error} />
      </Switch>
      <Footer settings={allSettings} />
    </div>
  );
}
export default App;
