import React from "react";
import "./Error.scss";
import { Link } from "react-router-dom";
import { _t } from "../../helpers/helpers";

const Error = () => {
  return (
    <div className="errorPage">
      <div className="errorPageChild">
        <div className="container">
          <div className="row">
            <div className="xl-6 lg-6 md-6 sm-12"></div>
            <div className="xl-6 lg-6 md-6 sm-12">
              <div className="errorPage-right">
                <h2>{_t("page.not_found")}</h2>
                {/* <p>Bunun əvəzinə:</p>
                <div className="tags">
                  <Link to="">{_t("sidebar.products")}</Link>
                </div> */}
                {/* <span className="moreSpan">və ya:</span> */}

                <div className="blackButtonParent">
                  <Link to="/" className="blackButton">
                    {_t("cart.back_to_shop")}
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Error;
