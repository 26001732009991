import React, { useContext, useEffect, useState } from "react";
import "./ProductCard.scss";
import { Link, useParams } from "react-router-dom";
import { _t, addToCartItem, deleteCartItem } from "../../helpers/helpers";
import { ReactComponent as Manat } from "../../assets/manat.svg";
import { ReactComponent as CardCompare } from "../../assets/card2.svg";
import { ReactComponent as CardHeart } from "../../assets/card.svg";
import ApiService from "../../api/axios";

import { ReactComponent as CardCart } from "../../assets/card3.svg";
import { ReactComponent as Add } from "../../assets/add.svg";
import { ReactComponent as Check } from "../../assets/Check.svg";
import { addTosWishlist } from "../../helpers/functions";
import { addToCompare } from "../../helpers/functions";
import { quantityContext, tokenContext, totalContext } from "../Store";
import Swal from "sweetalert2";
import { Rating } from "react-simple-star-rating";
import { useRef } from "react";
import CountModal from "../Modal/CountModal";
import {
  LazyLoadImage,
  trackWindowScroll,
} from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { QuantityPicker } from "react-qty-picker";
import { ReactComponent as Cart } from "../../assets/Cart.svg";
import { ReactComponent as Cross } from "../../assets/Cross.svg";
import { useDispatch } from "react-redux";
import { addToCart as addToCardAction } from "./../../store/cartSlice";
import { Swiper, SwiperSlide } from "swiper/react";
import { A11y, Navigation, Pagination, Thumbs } from "swiper";

const ProductCard = ({ product, onSelect, productMarkerId }) => {
  const [token, setToken] = useContext(tokenContext);
  const [quantity, setQuantity] = useContext(quantityContext);
  const [total, setTotal] = useContext(totalContext);
  const [count, setCount] = useState(1);
  const restorationRef = useRef(null);
  // const [buttonClass, setButtonClass] = useState("addToCard");
  const [cartActive, setCartActive] = useState("");
  const dispatch = useDispatch();
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const [redButton, setRedButton] = useState("");
  useEffect(() => {
    if (product.in_basket == true) {
      setCartActive("active");
    } else {
      setCartActive("");
    }

    if (productMarkerId != product.id) {
      return;
    }
    restorationRef.current.scrollIntoView({
      behavior: "auto",
      block: "center",
    });
    console.log(product);
    console.log(product.in_basket);
    sessionStorage.removeItem("scroll-position-product-id-marker");
  }, []);
  const handleRedButton = () => {
    setRedButton(redButton === "" ? "active" : "active");
  };
  const addToCart = (e, product) => {
    if (e.target.checked) {
      Swal.fire({
        title: _t("title.product_basket_count"),
        input: "number",
        inputAttributes: { autocapitalize: "off" },
        inputPlaceholder: _t("title.product_basket_count"),
        html:
          '<div className="productCard ">' +
          '  <div className="productCardHead">\n' +
          '     <img width="250" src="' +
          product.title_image +
          '" alt="productCard"/>\n' +
          "  </div>" +
          '<div className="productCardBody">\n' +
          "    <h6>" +
          product.title +
          "</h6>\n" +
          "    <p>" +
          product.description +
          "</p>\n" +
          "</div>\n" +
          "</div>",
        showCancelButton: true,
        confirmButtonText: _t("buttons.add_to_basket"),
        showLoaderOnConfirm: true,
        preConfirm: (pr_count) => {
          dispatch(addToCardAction({ product, count: pr_count }));
          Swal.fire({
            position: "center",
            icon: "success",
            title: _t("message.basket_added"),
            showConfirmButton: false,
            timer: 1000,
          });
          // return addToCartItem(product.id, pr_count).then((resp) => {
          //   setQuantity(resp.data.total_count);

          //   localStorage.token
          //     ? ApiService.get("cart/user/price").then((resp) => {
          //         setTotal(resp.data.total_price);
          //       })
          //     : ApiService.get("cart/guest/price", {
          //         params: { guest_uid: localStorage.guest_uid },
          //       }).then((resp) => {
          //         setTotal(resp.data.total_price);
          //       });
          // });
        },
        allowOutsideClick: () => !Swal.isLoading(),
      });
    } else {
      deleteCartItem(product.id).then((resp) => {
        Swal.fire({
          position: "center",
          icon: "success",
          title: _t("message.basket_delete"),
          showConfirmButton: false,
          timer: 1000,
        });
        setQuantity(resp.data.total_count);
        localStorage.token
          ? ApiService.get("cart/user/price").then((resp) => {
              setTotal(resp.data.total_price);
            })
          : ApiService.get("cart/guest/price", {
              params: { guest_uid: localStorage.guest_uid },
            }).then((resp) => {
              setTotal(resp.data.total_price);
            });
      });
    }
  };
  const [isActive, setIsActive] = useState(false);
  const handleClick = () => {
    setIsActive(!isActive);
  };
  const handlePopup = () => {
    setIsActive(false);
  };
  return (
    <>
      {isActive ? (
        // <AddToCartPopup
        //   key={`product_item_${props.product.id}`}
        //   product={props.product}
        // />
        <div className="popup popupCard">
          <div className="popupContainer">
            <div className="popupCardInner">
              <div className="crossButton" onClick={handlePopup}>
                <Cross />
              </div>
              <div className="popupCardLeft">
                <LazyLoadImage
                  src={product.title_image}
                  alt="productCard"
                  effect="blur"
                />
              </div>
              <div className="popupCardRight">
                <h3>{product.title}</h3>
                <div className="price">
                  {product.sale_price != 0 &&
                  product.sale_price != product.price ? (
                    <>
                      {product.sale_price}
                      <Manat />
                    </>
                  ) : (
                    ""
                  )}
                  {product.sale_price != 0 &&
                  product.sale_price != product.price ? (
                    <span>
                      {product.price}
                      <Manat />
                    </span>
                  ) : (
                    <>
                      {product.price}
                      <Manat />
                    </>
                  )}
                </div>

                <div className="popupButtonList">
                  <div className="quantity">
                    <QuantityPicker
                      value={count}
                      min={0}
                      max={99999}
                      onChange={(newValue) => {
                        setCount(newValue);
                      }}
                    />
                  </div>

                  {console.log(product.out_of_stock)}
                  {product.out_of_stock === 0 ? (
                    <button
                      className={`redButton ${redButton}`}
                      onClick={(e) => {
                        if (count == 0) return;
                        dispatch(addToCardAction({ ...product, count }));
                        Swal.fire({
                          position: "center",
                          icon: "success",
                          title: _t("message.basket_added"),
                          showConfirmButton: false,
                          timer: 1000,
                        });
                        setIsActive(false);
                        setCartActive("active");
                        handleRedButton();
                      }}
                    >
                      <Cart />
                      {_t("buttons.add_to_basket")}
                    </button>
                  ) : (
                    <div className="popupProductCardStok">
                      <span className="outStok">
                        {_t("forms.out_of_stock")}
                      </span>

                      <button
                        disabled
                        className={`redButton ${redButton}`}
                        onClick={(e) => {
                          if (count == 0) return;
                          dispatch(addToCardAction({ ...product, count }));
                          Swal.fire({
                            position: "center",
                            icon: "success",
                            title: _t("message.basket_added"),
                            showConfirmButton: false,
                            timer: 1000,
                          });
                          setIsActive(false);
                          setCartActive("active");
                          handleRedButton();
                        }}
                      >
                        <Cart />
                        {_t("buttons.add_to_basket")}
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}
      <div className="productCard " ref={restorationRef}>
        <div className="productCardHead">
          {/*<Link*/}
          {/*    to={`/products/${product.category.alias}/${product.alias}`}*/}
          {/*    onClick={() => {*/}
          {/*      onSelect(product.id);*/}
          {/*    }}*/}
          {/*>*/}
          {/*  <LazyLoadImage*/}
          {/*      src={product.title_image}*/}
          {/*      alt="productCard"*/}
          {/*      effect="blur"*/}
          {/*  />*/}
          {/*</Link>*/}

          <Swiper
            spaceBetween={20}
            slidesPerView={1}
            modules={[Navigation, Thumbs, Pagination, A11y]}
            pagination={{ clickable: true }}
          >
            <SwiperSlide>
              <Link
                style={{ height: "100%" }}
                to={`/products/${product.category.alias}/${product.alias}`}
                onClick={() => {
                  onSelect(product.id);
                }}
              >
                <LazyLoadImage
                  src={product.title_image}
                  alt="productCard"
                  effect="blur"
                />
              </Link>
            </SwiperSlide>
            {product.media &&
                product.media.slice(0, 7).map((image) => {
                return (
                  <SwiperSlide key={image.id}>
                    <Link
                      style={{ height: "100%" }}
                      to={`/products/${product.category.alias}/${product.alias}`}
                      onClick={() => {
                        onSelect(product.id);
                      }}
                    >
                      <LazyLoadImage
                        src={image.main}
                        alt="productCard"
                        effect="blur"
                      />
                    </Link>
                  </SwiperSlide>
                );
              })}
          </Swiper>

          <div className="trash">
            <label className="heart">
              <input
                onClick={(e) => addTosWishlist(e, product.id)}
                className="like-button"
                checked={product.is_wished ? "checked" : null}
                type="checkbox"
              />
              {/* <Trash /> */}
            </label>
          </div>
          {product.campaign <= 0 || null ? null : (
            <div
              className="badge"
              style={{ backgroundColor: product.campaign.color }}
            >
              {product.campaign.title}
            </div>
          )}
          {product.sale_percent <= 0 ? null : (
            <div className="discount">-{product.sale_percent}%</div>
          )}
          {product.out_of_stock == 0 ? null : (
            <div className="badge outofStock">{_t("forms.out_of_stock")}</div>
          )}
        </div>
        <div className="productCardBody">
          <h6 className="swiper-no-swiping">{product.title}</h6>
          <p>{product.description}</p>
          <div className="sku swiper-no-swiping">
            {_t("title.product.code")} :<span> {product.sku}</span>
          </div>
          <div className="productCardPrice">
            <div className="price">
              {product.sale_price != 0 &&
              product.sale_price != product.price ? (
                <>
                  {product.sale_price}
                  <Manat />
                </>
              ) : (
                ""
              )}
              {product.sale_price != 0 &&
              product.sale_price != product.price ? (
                <span>
                  {product.price}
                  <Manat />
                </span>
              ) : (
                <>
                  {product.price}
                  <Manat />
                </>
              )}
            </div>

            <div className="productCardRating resp-none">
              <Rating
                initialValue={product.rating}
                fillColor={"#FF0852"}
                readonly={true}
                allowHalfIcon={true}
                iconsCount={5}
                size={20}
                ratingValue={product.rating * 20} /* Available Props */
              />
            </div>
          </div>
        </div>
        <div className="svg">
          <Check />
        </div>

        <div className="productCardBottom desk-none">
          {token ? (
            <>
              <label className="heart ProductCardButtons">
                <input
                  onClick={(e) => addToCompare(e, product.id)}
                  className="like-button"
                  type="checkbox"
                  checked={product.in_compare ? "checked" : null}
                />
                <div className="clickDone">
                  <Add />
                  <Check />
                  <CardCompare />
                </div>
              </label>

              <label className="heart ProductCardButtons">
                <input
                  onClick={(e) => addTosWishlist(e, product.id)}
                  className="like-button"
                  checked={product.is_wished ? "checked" : null}
                  type="checkbox"
                />
                <div className="clickDone">
                  <Add />
                  <Check />
                  <CardHeart />
                </div>
              </label>

              <label
                className={`heart ProductCardButtons cartClassParent ${cartActive}`}
                onClick={handleClick}
              >
                <input type="checkbox" className="like-button" />
                <div className="cartClass">
                  <Add />
                  <Check />
                  <CardCart />
                </div>
              </label>
            </>
          ) : (
            <>
              <label
                className={`heart ProductCardButtons cartClassParent ${cartActive}`}
                onClick={handleClick}
              >
                <input className="like-button" type="checkbox" />
                <div className="cartClass">
                  <Add />
                  <Check />
                  <CardCart />
                </div>
              </label>
            </>
          )}
        </div>
        <div className="productCardLayerIcons resp-none">
          <Link
            to={`/products/${product.category.alias}/${product.alias}`}
            onClick={() => {
              onSelect(product.id);
            }}
            className="productCardLayerInner"
          ></Link>
          {token ? (
            <>
              <label className="heart ProductCardButtons">
                <input
                  type="checkbox"
                  className="like-button"
                  checked={product.in_compare ? "checked" : null}
                  onClick={(e) => addToCompare(e, product.id)}
                />
                <div className="clickDone">
                  <Add />
                  <Check />
                  <CardCompare />
                </div>
              </label>

              <label className="heart ProductCardButtons">
                <input
                  onClick={(e) => addTosWishlist(e, product.id)}
                  className="like-button"
                  checked={product.is_wished ? "checked" : null}
                  type="checkbox"
                />
                <div className="clickDone">
                  <Add />
                  <Check />
                  <CardHeart />
                </div>
              </label>

              <label
                className={`heart ProductCardButtons cartClassParent ${cartActive}`}
                onClick={handleClick}
              >
                <input type="checkbox" className="like-button" />
                <div className="cartClass">
                  <Add />
                  <Check />
                  <CardCart />
                </div>
              </label>
            </>
          ) : (
            <>
              <label
                className={`heart ProductCardButtons cartClassParent ${cartActive}`}
                onClick={handleClick}
              >
                <input className="like-button" type="checkbox" />
                <div className="cartClass">
                  <Add />
                  <Check />
                  <CardCart />
                </div>
              </label>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default trackWindowScroll(ProductCard);
