import React, { useEffect, useState } from "react";
import "../index.scss";
import AccountSideBar from "../../../components/AccountSideBar";
import InputMask from "react-input-mask";
import { ReactComponent as Tick } from "../../../assets/tick.svg";
import ApiService from "../../../api/axios";
import Meta from "../../../components/Meta/Meta";
import BreadCrumb from "../../../components/BreadCrumb";
import { _t } from "../../../helpers/helpers";
import Swal from "sweetalert2";

const PersonalInfo = () => {
  // const options = [
  //   { value: "Baku", label: "Bakı" },
  //   { value: "Ganca", label: "Gəncə" },
  //   { value: "Sumqayit", label: "Sumqayıt" },
  // ];
  // const customStyles = {
  //   option: (provided, state) => ({
  //     ...provided,
  //     fontStyle: "normal",
  //     fontSize: "1.8rem",
  //     lineHeight: "2.1rem",
  //     color: "#121212",
  //     borderRadius: "1.5rem",
  //     fontWeight: 500,

  //     "&:hover": {
  //       backgroundColor: "#FDFCFD",
  //     },
  //     backgroundColor: state.isSelected ? "#FDFCFD" : "#F3F3F3",
  //   }),
  //   menu: (provided) => ({
  //     ...provided,
  //     background: " #F3F3F3",
  //     boxShadow: "0 2rem 2rem rgba(204, 204, 204, 0.15)",
  //     borderRadius: "1.5rem",
  //     overflow: "hidden",
  //     padding: "2.4rem",
  //   }),
  //   control: (provided) => ({
  //     ...provided,
  //     border: "none",
  //     borderBottom: ".1rem solid #DEDEDE",
  //     boxShadow: "none",
  //     fontStyle: "normal",
  //     fontWeight: 600,
  //     fontSize: "1.8rem",
  //     lineHeight: "2.5rem",
  //     color: "#121212",
  //     padding: 0,
  //     borderRadius: "none",
  //     height: "3.2rem !important",
  //     maxHeight: "3.2rem",
  //     minHeight: "4rem",
  //   }),

  //   singleValue: (provided) => ({
  //     ...provided,
  //     padding: 0,
  //     fontWeight: 600,
  //     boxShadow: "none",
  //   }),
  // };
  // const [account, setAccount] = useState({});
  const [pageMeta, setPageMeta] = useState({});
  const [breadcrumbs, setBreadcrumbs] = useState([]);
  const [full_name, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  // const [city, setCity] = useState("");
  useEffect(() => {
    ApiService.get("account").then((resp) => {
      // setAccount(resp.data.user);
      setPageMeta(resp.data.page_meta);
      setBreadcrumbs(resp.data.page_meta.breadcrumbs);
      setFullName(resp.data.user.fullname);
      setEmail(resp.data.user.email);
      setPhone(resp.data.user.phone);
    });
  }, []);
  const handleForm = (e) => {
    e.preventDefault();
    ApiService.put("account", { full_name, email, phone }).then((resp) => {
      setFullName(resp.data.user.fullname);
      setEmail(resp.data.user.email);
      setPhone(resp.data.user.phone);
      Swal.fire(_t("success.title"), _t("success.message"), "success");
    });
  };
  return (
    <div className="personalInfo account">
      <Meta meta={pageMeta} />

      {/* <div className="container"> */}
      <div className="accoutRow">
        <div className="accountSidebar">
          <div className="accountSideBarChild">
            <BreadCrumb breadcrumbs={breadcrumbs} />
            <h1>{_t("title.profile.info")}</h1>
            <AccountSideBar />
          </div>
        </div>
        <div className="AccountRight">
          <form onSubmit={handleForm} className="accountRightInner">
            <div className="title">{_t("title.profile.info")}</div>
            <div className="input-group">
              <div className="input-child">
                <label htmlFor="">{_t("forms_name_surname")}</label>
                <input
                  type="text"
                  value={full_name}
                  placeholder={_t("forms_name_surname")}
                  onChange={(e) => setFullName(e.target.value)}
                />
              </div>
              <div className="input-child">
                <label htmlFor="">{_t("forms.numbers")}</label>
                <InputMask
                  mask="+\9\9\4999999999"
                  maskChar=" "
                  value={phone}
                  placeholder={_t("forms.numbers")}
                  onChange={(e) => setPhone(e.target.value)}
                  // disabled
                />
              </div>
            </div>
            <div className="input-group">
              <div className="input-child">
                <label htmlFor="">{_t("forms.emails")}</label>
                <input
                  type="text"
                  value={email}
                  placeholder={_t("forms.emails")}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
            </div>
            {/* <div className="input-group">
              <div className="input-child">
                <label htmlFor="">Email</label>
                <input type="email" placeholder="Email adresinizi daxil edin" />
              </div>
              <div className="input-child resp-none"></div>
            </div> */}
            <div className="accountSave">
              <button>
                <Tick />
                {_t("buttons.save")}
              </button>
            </div>
          </form>
        </div>
      </div>
      {/* </div> */}
    </div>
  );
};

export default PersonalInfo;
