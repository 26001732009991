import React, { useEffect, useState } from "react";
import "./About.scss";
import InfoBar from "../../components/Homepage/TopInfoBar";
import ApiService from "../../api/axios";
import Meta from "../../components/Meta/Meta";
import BreadCrumb from "../../components/BreadCrumb";
import { _t } from "../../helpers/helpers";
import Brand from "../../components/Homepage/Brand";
import {
  LazyLoadImage,
  trackWindowScroll,
} from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

const About = () => {
  const [breadcrumbs, setBreadcrumbs] = useState([]);
  const [pageMeta, setPageMeta] = useState([]);
  const [about, setAbout] = useState({
    page_blocks: [
      {
        title_image: "",
      },
    ],
    media: [],
  });

  useEffect(() => {
    ApiService.get("pageByUrl?url=about").then((resp) => {
      setAbout(resp.data.data);
      setPageMeta(resp.data.page_meta);
      setBreadcrumbs(resp.data.page_meta.breadcrumbs);
    });
  }, []);
  return (
    <div className="about">
      <Meta meta={pageMeta} />
      <div className="container">
        <BreadCrumb breadcrumbs={breadcrumbs} />
        <div className="row aboutparent">
          <div className="xl-12 lg-12 md-12 sm-12">
            <div className="aboutCard aboutCardTop">
              <h2>{about.title}</h2>
              <div
                dangerouslySetInnerHTML={{
                  __html: about.content,
                }}
              />
            </div>
          </div>
          <div className="xl-12 lg-12 md-12 sm-12">
            <div className="img-container">
              <LazyLoadImage
                src={about.title_image}
                alt="about"
                className="aboutImg"
                effect="blur"
              />
            </div>
          </div>
        </div>
        <div className="infobar">
          <InfoBar />
        </div>
        <div className="aboutUpperParent">
          {about.page_blocks.map((item) => {
            return (
              <div className="row aboutparent resp-about">
                <div className="xl-6 lg-6 md-6 sm-12">
                  <div className="img-container">
                    <LazyLoadImage
                      src={item.title_image}
                      alt="about"
                      className="aboutImg"
                      effect="blur"
                    />
                  </div>
                </div>
                <div className="xl-6 lg-6 md-6 sm-12">
                  <div className="aboutCard left">
                    <h2>{item.title}</h2>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: item.content,
                      }}
                    />
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        <div className="aboutmeParent">
          {about.page_blocks.map((items) => {
            return (
              <div className="row aboutme resp-about">
                <div className="xl-6 lg-6 md-6 sm-12">
                  <div className="img-container">
                    <LazyLoadImage
                      src={items.title_image}
                      alt="man"
                      className="aboutImg"
                      effect="blur"
                    />
                  </div>
                </div>
                <div className="xl-6 lg-6 md-6 sm-12">
                  <div className="aboutmeInner">
                    <div className="title">{items.title}</div>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: items.description,
                      }}
                    />
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        {/* <div className="row aboutSertifcade">
          <div className="xl-12 lg-12 md-12 sm-12">
            <div className="title">{_t("title_certificates")}</div>
          </div>
          <div className="xl-12 lg-12 md-12 sm-12">
            <div className="row about-row-resp">
              {about.media.map((item) => {
                return (
                  <div className="xl-4 lg-4 md-4 sm-12">
                    <div className="sertifcate-card">
                      <div className="img-container">
                        <img src={item.slider} alt="Certificate" />
                      </div>
                      <span>{item.title}</span>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div> */}
        <div className="partners">
          <Brand />
        </div>
      </div>
    </div>
  );
};

export default trackWindowScroll(About);
