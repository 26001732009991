import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import "./SupportSidebar.scss";
import { _t } from "../../helpers/helpers";
import ApiService from "../../api/axios";

const AccountSideBar = () => {
  const [sideBar, setSideBar] = useState([]);
  useEffect(() => {
    ApiService.get("helpers/menu/support_menu").then((resp) => {
      setSideBar(resp.data.data);
    });
  }, []);
  return (
    <>
      <div className="AccountSideBar">
        {sideBar.map((side) => {
          return (
            <NavLink to={`${side.path}`} activeClassName="active">
              <div
                dangerouslySetInnerHTML={{
                  __html: side.svg_icon,
                }}
              />
              <span>{side.title}</span>
            </NavLink>
          );
        })}
      </div>
    </>
  );
};

export default AccountSideBar;
