import React, { useState, useContext, useEffect } from "react";
import { NavLink, Link, useHistory } from "react-router-dom";
import "./AccountSideBar.scss";
import { ReactComponent as User } from "../../assets/userCheck.svg";
import { ReactComponent as Fav } from "../../assets/fav.svg";
import { ReactComponent as Order } from "../../assets/resept.svg";
import { ReactComponent as Lock } from "../../assets/password.svg";
import Select from "react-select";
import { ReactComponent as LogOut } from "../../assets/logout.svg";
import Swal from "sweetalert2";
import ApiService from "../../api/axios";
import { fullNameContext, quantityContext, tokenContext } from "../Store";
import { _t, getTotalCount } from "../../helpers/helpers";
import { ReactComponent as Pin } from "../../assets/Icon.svg";
import { useDispatch } from "react-redux";
import { initialCartData } from "../../store/cartSlice";
const AccountSideBar = () => {
  const [selectedOption, setSelectedOption] = useState(null);
  let history = useHistory();
  const [token, setToken] = useContext(tokenContext);
  const [fullName, setFullName] = useContext(fullNameContext);
  const [quantity, setQuantity] = useContext(quantityContext);
  const dispatch = useDispatch();

  const logOut = () => {
    if (localStorage.token) {
      ApiService.post("account/logout", {
        headers: { Authorization: `Bearer ${token}` },
      }).then((resp) => {
        Swal.fire({
          title: _t("warning.delete_question"),
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "OK",
        }).then((result) => {
          if (result.isConfirmed) {
            history.push("/login");
            Swal.fire(resp.data.title, resp.data.message, "success");

            setToken(null);
            setFullName(null);
            localStorage.removeItem("token");
            localStorage.removeItem("full_name");
            setQuantity(getTotalCount());
            if (localStorage.token) {
              ApiService.get("/cart/user/list").then((res) => {
                dispatch(initialCartData(res.data.data));
              });
            } else {
              ApiService.get("/cart/guest/list", {
                params: { guest_uid: localStorage.guest_uid },
              }).then((res) => {
                dispatch(initialCartData(res.data.data));
              });
            }
          }
        });
      });
    }
  };
  const [bonus, setBonus] = useState("");
  useEffect(() => {
    ApiService.get("account").then((resp) => {
      setBonus(resp.data.user.bonusFull);
    });
  });

  const options = [
    {
      value: `${_t("title_personal_info")}`,
      label: (
        <NavLink
          activeClassName="active"
          to="/account/personal-info"
          className="filterInner"
        >
          <User />
          {_t("title_personal_info")}
        </NavLink>
      ),
    },
    {
      value: `${_t("pages.favorites.title")}`,
      label: (
        <NavLink
          activeClassName="active"
          to="/account/favorites"
          className="filterInner"
        >
          <Fav />
          {_t("pages.favorites.title")}
        </NavLink>
      ),
    },
    {
      value: `${_t("pages.order_history.title")}`,
      label: (
        <NavLink
          activeClassName="active"
          to="/account/order-history"
          className="filterInner"
        >
          <Order />
          {_t("pages.order_history.title")}
        </NavLink>
      ),
    },
    {
      value: `${_t("pages.addresses.title")}`,
      label: (
        <NavLink
          activeClassName="active"
          to="/account/address"
          className="filterInner"
        >
          <Lock />
          {_t("pages.addresses.title")}
        </NavLink>
      ),
    },
    {
      value: `${_t("pages.change_password.title")}`,
      label: (
        <NavLink
          activeClassName="active"
          to="/account/change-password"
          className="filterInner"
        >
          <Lock />
          {_t("pages.change_password.title")}
        </NavLink>
      ),
    },
    {
      value: `${_t("buttons.logout")}`,
      label: (
        <div onClick={logOut} className="filterInner">
          <LogOut />
          {_t("buttons.logout")}
        </div>
      ),
    },
  ];
  const customStyles = {
    menu: (provided, state) => ({
      ...provided,
      border: "none",
      borderBottomRightRadius: "1.6rem",
      borderBottomLeftRadius: "1.6rem",
      overflow: "hidden",
      width: "100%",
      backgroundColor: "#F2F1F1",
    }),
    control: () => ({
      backgroundColor: "#fff",
      width: "100%",
      height: "5rem",
      display: "flex",
      alignItems: "center",
      fontStyle: "normal",
      fontWeight: "500",
      fontSize: "1.6rem",
      lineHeight: "2.2rem",
      color: "#FE0B0B",
      borderRadius: "3rem",
    }),
    option: (styles, state) => {
      return {
        ...styles,
        color: "#121212",
        fontSize: "1.4rem",
      };
    },
    singleValue: (provided, state) => ({
      ...provided,
      display: "flex",
      alignItems: "center",
      color: "#fe0b0b",
    }),
  };
  return (
    <>
      <div className="AccountSideBar">
        <div className="bonusSpan">
          {_t("title.cart.bonus")} :<span> {bonus == 0 ? "0" : bonus}</span>
        </div>
        <NavLink to="/account/personal-info" activeClassName="active">
          <User />
          <span>{_t("title_personal_info")}</span>
        </NavLink>
        <NavLink to="/account/order-history" activeClassName="active">
          <Order />
          <span> {_t("pages.order_history.title")}</span>
        </NavLink>
        <NavLink to="/account/favorites" activeClassName="active">
          <Fav />
          <span> {_t("pages.favorites.title")}</span>
        </NavLink>
        <NavLink to="/account/address" activeClassName="active">
          <Pin />
          <span> {_t("pages.addresses.title")}</span>
        </NavLink>
        <NavLink to="/account/change-password" activeClassName="active">
          <Lock />
          <span> {_t("pages.change_password.title")}</span>
        </NavLink>

        <Link onClick={logOut} className="logOutButton">
          <LogOut />
          <span> {_t("buttons.logout")}</span>
        </Link>
      </div>
      {/* <div className="accounSideBarResp">
        <Select
          styles={customStyles}
          onChange={setSelectedOption}
          options={options}
          components={{ IndicatorSeparator: () => null }}
          defaultValue={{
            label: (
              <div activeClassName="active" className="filterInner">
                <User />
                {_t("title_personal_info")}
              </div>
            ),
            value: `${_t("title_personal_info")}`,
          }}
        />
      </div> */}
    </>
  );
};

export default AccountSideBar;
