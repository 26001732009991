import React, { useContext } from "react";
import "./HeaderMiddle.scss";
import { Link, useLocation } from "react-router-dom";
// svg
import { ReactComponent as Call } from "../../../assets/call.svg";
import { ReactComponent as Logo } from "../../../assets/logo.svg";
import { ReactComponent as Swap } from "../../../assets/arrowSwap.svg";
import { ReactComponent as User } from "../../../assets/User.svg";
import { ReactComponent as Heart } from "../../../assets/Heart.svg";
import { ReactComponent as Cart } from "../../../assets/Cart.svg";

import {
  // cartItemsContext,
  // quantityContext,
  // localeContext,
  tokenContext,
  // fullNameContext,
  // headermenusContext,
  totalContext,
} from "../../Store";
import { _t } from "../../../helpers/helpers";
import { useSelector } from "react-redux";

const HeaderMiddle = (props) => {
  const [token, setToken] = useContext(tokenContext);
  const [total, setTotal] = useContext(totalContext);
  const location = useLocation();
  const cart = useSelector((state) => state.cart);

  // useEffect(() => {
  //   ApiService.post("/getCart").then((res) => {
  //     setTotalPrice(res.data.data.total);
  //   });
  // }, []);
  return (
    <div className="headerMiddle">
      <div className="container">
        <div className="headerMiddleContent">
          <a
            target="_blank"
            href={`tel:${props.number.phone}`}
            className="callButton"
          >
            <Call />
            {props.number.phone}
          </a>
          {location.pathname === "/" ? (
            <div className="logo deactive">
              <Logo />
            </div>
          ) : (
            <Link to="/" className="logo">
              <Logo />
            </Link>
          )}
          <div className="headerMiddleContentRight">
            {token ? (
              <Link to="/compare">
                <Swap />
              </Link>
            ) : null}

            {token ? (
              <Link to="/account/personal-info">
                <User />
              </Link>
            ) : (
              <Link to="/login">
                <User />
              </Link>
            )}
            {token ? (
              <Link to="/account/favorites">
                <Heart />
              </Link>
            ) : null}

            <Link to="/cart">
              <Cart />
            </Link>
            <Link to="/cart" className="headerProduct">
              <span>
                {cart.total}
                AZN
              </span>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeaderMiddle;
