import React, { useState, useEffect } from "react";
import "../index.scss";
import SupportSidebar from "../../../components/SupportSidebar";
import ApiService from "../../../api/axios";
import Meta from "../../../components/Meta/Meta";
import BreadCrumb from "../../../components/BreadCrumb";
import { _t } from "../../../helpers/helpers";

const Faq = () => {
  const [breadcrumbs, setBreadcrumbs] = useState([]);
  const [pageMeta, setPageMeta] = useState([]);
  const [faq, setFaq] = useState([]);
  useEffect(() => {
    ApiService.get("faq").then((resp) => {
      setPageMeta(resp.data.page_meta);
      setBreadcrumbs(resp.data.page_meta.breadcrumbs);
      setFaq(resp.data.data);
    });
  }, []);
  return (
    <div className="support">
      {/* <div className="container"> */}
      <div className="accoutRow">
        <div className="accountSidebar">
          <div className="accountSideBarChild">
            <Meta meta={pageMeta} />
            <BreadCrumb breadcrumbs={breadcrumbs} />
            <h1>{_t("title.support")}</h1>
            <SupportSidebar />
          </div>
        </div>
        <div className="AccountRight">
          <div className="accountRightInner">
            {faq.map((item) => {
              return (
                <div className="faqList">
                  <h2 className="title">{item.question}</h2>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: item.answer,
                    }}
                  />
                </div>
              );
            })}
          </div>
        </div>
      </div>
      {/* </div> */}
    </div>
  );
};

export default Faq;
