import React, { useState, useEffect } from "react";
import "../index.scss";
import SupportSidebar from "../../../components/SupportSidebar";
import BirCard from "../../../assets/bir.png";
import TamCard from "../../../assets/tam.png";
import AlbaliCard from "../../../assets/Albali.png";
import ApiService from "../../../api/axios";
import Meta from "../../../components/Meta/Meta";
import BreadCrumb from "../../../components/BreadCrumb";
import { _t } from "../../../helpers/helpers";

const Credit = () => {
  const [breadcrumbs, setBreadcrumbs] = useState([]);
  const [pageMeta, setPageMeta] = useState([]);
  const [credit, setCredit] = useState({
    media: [
      {
        slider: "",
      },
    ],
  });

  useEffect(() => {
    ApiService.get("pageByUrl?url=credit-cards").then((resp) => {
      setPageMeta(resp.data.page_meta);
      setBreadcrumbs(resp.data.page_meta.breadcrumbs);
      setCredit(resp.data.data);
    });
  }, []);
  return (
    <div className="support">
      {/* <div className="container"> */}
      <div className="accoutRow">
        <div className="accountSidebar">
          <div className="accountSideBarChild">
            <Meta meta={pageMeta} />
            <BreadCrumb breadcrumbs={breadcrumbs} />
            <h1>{_t("title.support")}</h1>
            <SupportSidebar />
          </div>
        </div>
        <div className="AccountRight">
          <div className="accountRightInner">
            <div className="subTitle">{credit.title}</div>
            <div className="bonusList">
              <div
                dangerouslySetInnerHTML={{
                  __html: credit.content,
                }}
              />
            </div>
            <div className="CreditCards">
              {credit.media.map((item) => {
                return (
                  <div className="img-container">
                    <img src={item.slider} alt="birkart" />
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
      {/* </div> */}
    </div>
  );
};

export default Credit;
