import React, { useEffect, useState } from "react";
import "./Contact.scss";
import { Link } from "react-router-dom";

import { ReactComponent as Facebook } from "../../assets/facebook.svg";
import { ReactComponent as Instagram } from "../../assets/instagram.svg";
import { ReactComponent as Youtube } from "../../assets/youtube.svg";
import { ReactComponent as Phone } from "../../assets/phone.svg";
import { ReactComponent as Wp } from "../../assets/whatsapp.svg";
import { ReactComponent as Mail } from "../../assets/mail.svg";
import ApiService from "../../api/axios";
import Meta from "../../components/Meta/Meta";
import BreadCrumb from "../../components/BreadCrumb";
import { _t } from "../../helpers/helpers";
import Swal from "sweetalert2";

const Contact = () => {
  const [breadcrumbs, setBreadcrumbs] = useState([]);
  const [pageMeta, setPageMeta] = useState([]);
  const [contact, setContact] = useState({});
  const [phone, setPhone] = useState("");
  const [userName, setUserName] = useState("");
  const [email, setEmail] = useState("");
  const [text, setText] = useState("");

  const [contactDetail, setContactDetail] = useState({
    address: {
      addresses_description: "",
      addresses_maps: "",
      addresses_title: "",
    },
  });
  useEffect(() => {
    ApiService.get("pageByUrl?url=about").then((resp) => {
      setContact(resp.data.data);
      setPageMeta(resp.data.page_meta);
      setBreadcrumbs(resp.data.page_meta.breadcrumbs);
    });
    ApiService.get("helpers/settings").then((res) => {
      setContactDetail(res.data.data);
      console.log(contactDetail.addresses_maps);
    });
  }, []);
  const handleContact = (e) => {
    ApiService.post("/form/contact", {
      email: email,
      phone: phone,
      full_name: userName,
      message: text,
    }).then((res) => {
      Swal.fire(res.data.title, res.data.message, "success");
      e.target.reset();
    });
  };
  return (
    <div className="contact">
      <Meta meta={pageMeta} />
      <div className="container">
        <BreadCrumb breadcrumbs={breadcrumbs} />
        <div className="row">
          <div className="xl-4 lg-4 md-4 sm-12">
            <div className="title">
              {_t("FRONTEND.CONTACT_FORM_TITLE")}
              <span>{_t("title_question_for_us")} ?</span>
            </div>
            <form action="" onSubmit={handleContact}>
              <div className="input-group user">
                <div className="input-child">
                  <input
                    type="text"
                    placeholder={_t("forms_name_surname")}
                    onChange={(e) => setUserName(e.target.value)}
                  />
                </div>
              </div>
              <div className="input-group phone">
                <div className="input-child">
                  <input
                    type="number"
                    placeholder={_t("forms.numbers")}
                    onChange={(e) => setPhone(e.target.value)}
                  />
                </div>
              </div>
              <div className="input-group mail">
                <div className="input-child">
                  <input
                    type="mail"
                    placeholder={_t("forms.emails")}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
              </div>
              <div className="input-group chat">
                <span>{_t("title.contact_form")}</span>
                <div className="input-child">
                  <textarea
                    name=""
                    id=""
                    cols="30"
                    rows="10"
                    onChange={(e) => setText(e.target.value)}
                  ></textarea>
                </div>
              </div>
              <div className="input-group">
                <button className="blackButton">{_t("buttons.send")}</button>
              </div>
            </form>
            <div className="socialIcons">
              <a href={contactDetail.instagram} target="_blank">
                <Instagram />
              </a>
              <a href={contactDetail.youtube} target="_blank">
                <Youtube />
              </a>
              <a href={contactDetail.facebook} target="_blank">
                <Facebook />
              </a>
            </div>
          </div>
          <div className="xl-8 lg-8 md-8 sm-12">
            <div className="contactInner">
              <div className="contactHeader">
                <div className="title">
                  {_t("title.contact")}
                  {/* <span>
                    {contactDetail.address.addresses_title}:{" "}
                    {contactDetail.address.addresses_description}
                  </span> */}
                </div>
                <div className="contactDetail">
                  <a href={`tel:${contactDetail.number}`}>
                    <Phone />
                    {contactDetail.number}
                  </a>
                  <a href={contactDetail.wp_link} target="_blank">
                    <Wp />
                    {contactDetail.number}
                  </a>
                  <a href={`mailto:${contactDetail.email}`}>
                    <Mail />
                    {contactDetail.email}
                  </a>
                </div>
              </div>
            </div>
            <div className="map">
              <iframe src={contactDetail.address.addresses_maps}></iframe>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
