import React, { useEffect, useState } from "react";
import "./TopInfoBar.scss";
import ApiService from "../../../api/axios";
const TopInfoBar = () => {
  const [infobar, setInfobar] = useState([]);
  useEffect(() => {
    ApiService.get("index/about").then((resp) => {
      setInfobar(resp.data.content.informers);
      console.log(resp.data.content.informers);
    });
  }, []);
  return (
    <div className="topInfoBar">
      <div className="container">
        <div className="topInfoBarInner">
          <div className="row">
            {infobar.map((item) => {
              return (
                <div className="xl-3 lg-3 md-3 sm-3">
                  <div className="topInfoCard">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: item.svg,
                      }}
                    />
                    <span>{item.title}</span>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopInfoBar;
