import React, { useEffect, useState } from "react";
import "./News.scss";
import { ReactComponent as Time } from "../../assets/Time.svg";
import { Link } from "react-router-dom";
import { ReactComponent as Arrow } from "../../assets/arrowRight.svg";
import ApiService from "../../api/axios";
import Meta from "../../components/Meta/Meta";
import BreadCrumb from "../../components/BreadCrumb";
import { _t } from "../../helpers/helpers";

const News = () => {
  const [newsList, setNewsList] = useState([]);
  const [newsTotal, setNewsTotal] = useState([]);
  const [breadcrumbs, setBreadcrumbs] = useState([]);
  const [pageMeta, setPageMeta] = useState([]);

  useEffect(() => {
    ApiService.get("news/list").then((resp) => {
      setNewsList(resp.data.data);
      setNewsTotal(resp.data.total);
      setPageMeta(resp.data.page_meta);
      setBreadcrumbs(resp.data.page_meta.breadcrumbs);
    });
  }, []);
  return (
    <div className="news">
      <Meta meta={pageMeta} />

      <div className="container">
        <BreadCrumb breadcrumbs={breadcrumbs} />
        <div className="newsTitle">
          <span>
            ({newsTotal} {_t("sidebar.news")})
          </span>
        </div>
        <div className="rowNews">
          {newsList.map((news) => {
            return (
              <Link to={`/blog/${news.alias}`} className="row newsParent">
                <div className="xl-7 lg-7 md-7 sm-12">
                  <div className="img-container">
                    <div className="layerNews">
                      {_t("button.read_more")}
                      <Arrow />
                    </div>
                    <img src={news.title_image} alt="news" />
                  </div>
                </div>
                <div className="xl-5 lg-5 md-5 sm-12">
                  <div className="newsCard left">
                    <div className="news-card-inner">
                      <div className="time">
                        <Time />
                        {news.dateHuman}
                        {/* <span>
                          <Eye />
                          201
                        </span> */}
                      </div>
                      <h2>{news.title}</h2>
                      <p
                        dangerouslySetInnerHTML={{
                          __html: news.content,
                        }}
                      />
                    </div>
                  </div>
                </div>
              </Link>
            );
          })}

          {/* <Link to="/news-detail" className="row newsParent">
            <div className="xl-7 lg-7 md-7 sm-12">
              <div className="img-container">
                <div className="layerNews">
                  Daha ətraflı bax
                  <Arrow />
                </div>
                <img src={NewsImg} alt="news" />
              </div>
            </div>
            <div className="xl-5 lg-5 md-5 sm-12">
              <div className="newsCard left">
                <div className="news-card-inner">
                  <div className="time">
                    <Time />
                    10 December 2022
                    <span>
                      <Eye />
                      201
                    </span>
                  </div>
                  <h2>How to Choose the right Color Palette for Home</h2>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Porttitor suspendisse amet aliquam gravida viverra. Proin
                    diam nibh tortor sed sed libero.
                  </p>
                </div>
              </div>
            </div>
          </Link>
          <Link to="/news-detail" className="row newsParent">
            <div className="xl-7 lg-7 md-7 sm-12">
              <div className="img-container">
                <div className="layerNews">
                  Daha ətraflı bax
                  <Arrow />
                </div>
                <img src={NewsImg} alt="news" />
              </div>
            </div>
            <div className="xl-5 lg-5 md-5 sm-12">
              <div className="newsCard left">
                <div className="news-card-inner">
                  <div className="time">
                    <Time />
                    10 December 2022
                    <span>
                      <Eye />
                      201
                    </span>
                  </div>
                  <h2>How to Choose the right Color Palette for Home</h2>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Porttitor suspendisse amet aliquam gravida viverra. Proin
                    diam nibh tortor sed sed libero.
                  </p>
                </div>
              </div>
            </div>
          </Link>
          <Link to="/news-detail" className="row newsParent">
            <div className="xl-7 lg-7 md-7 sm-12">
              <div className="img-container">
                <div className="layerNews">
                  Daha ətraflı bax
                  <Arrow />
                </div>
                <img src={NewsImg} alt="news" />
              </div>
            </div>
            <div className="xl-5 lg-5 md-5 sm-12">
              <div className="newsCard left">
                <div className="news-card-inner">
                  <div className="time">
                    <Time />
                    10 December 2022
                    <span>
                      <Eye />
                      201
                    </span>
                  </div>
                  <h2>How to Choose the right Color Palette for Home</h2>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Porttitor suspendisse amet aliquam gravida viverra. Proin
                    diam nibh tortor sed sed libero.
                  </p>
                </div>
              </div>
            </div>
          </Link>
          <Link to="/news-detail" className="row newsParent">
            <div className="xl-7 lg-7 md-7 sm-12">
              <div className="img-container">
                <div className="layerNews">
                  Daha ətraflı bax
                  <Arrow />
                </div>
                <img src={NewsImg} alt="news" />
              </div>
            </div>
            <div className="xl-5 lg-5 md-5 sm-12">
              <div className="newsCard left">
                <div className="news-card-inner">
                  <div className="time">
                    <Time />
                    10 December 2022
                    <span>
                      <Eye />
                      201
                    </span>
                  </div>
                  <h2>How to Choose the right Color Palette for Home</h2>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Porttitor suspendisse amet aliquam gravida viverra. Proin
                    diam nibh tortor sed sed libero.
                  </p>
                </div>
              </div>
            </div>
          </Link> */}
        </div>
      </div>
    </div>
  );
};
export default News;
