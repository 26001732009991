import React, { useEffect, useState } from "react";
import ApiService from "../api/axios";
// import Quantity from "./Quantity";
export const cartItemsContext = React.createContext("cartItems");
export const quantityContext = React.createContext("quantity");
export const localeContext = React.createContext("localeContext");
export const tokenContext = React.createContext("tokenContext");
export const fullNameContext = React.createContext("fullNameContext");
export const headermenusContext = React.createContext("headermenusContext");
export const compareItemsContext = React.createContext("compareItems");
export const isLoadingContext = React.createContext("isLoadingContext");
export const totalContext = React.createContext("totalContext");
const Store = ({ children }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [total, setTotal] = useState(0);

  const [cartItems, setCartItems] = useState([]);
  const [compareItems, setCompareItems] = useState(
    localStorage.compareItems ? JSON.parse(localStorage.compareItems) : []
  );
  const [quantity, setQuantity] = useState(0);
  const [locale, setLocale] = useState(
    sessionStorage.locale ? sessionStorage.locale : "az"
  );
  const [token, setToken] = useState(localStorage.token);
  const [fullName, setFullName] = useState(localStorage.full_name);
  const [headermenus, setHeadermenus] = useState(() => {
    ApiService.get("helpers/menu/header_menu").then((resp) => {
      return resp.data.data;
    });
  });

  return (
    <cartItemsContext.Provider value={[cartItems, setCartItems]}>
      <compareItemsContext.Provider value={[compareItems, setCompareItems]}>
        <quantityContext.Provider value={[quantity, setQuantity]}>
          <localeContext.Provider value={[locale, setLocale]}>
            <tokenContext.Provider value={[token, setToken]}>
              <totalContext.Provider value={[total, setTotal]}>
                <fullNameContext.Provider value={[fullName, setFullName]}>
                  <headermenusContext.Provider
                    value={[headermenus, setHeadermenus]}
                  >
                    <isLoadingContext.Provider
                      value={[isLoading, setIsLoading]}
                    >
                      {children}
                    </isLoadingContext.Provider>
                  </headermenusContext.Provider>
                </fullNameContext.Provider>
              </totalContext.Provider>
            </tokenContext.Provider>
          </localeContext.Provider>
        </quantityContext.Provider>
      </compareItemsContext.Provider>
    </cartItemsContext.Provider>
  );
};

export default Store;
