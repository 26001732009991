import React, { useEffect, useState } from "react";
import "./Brand.scss";
import { ReactComponent as Arrow } from "../../../assets/arrowRight.svg";
import ApiService from "../../../api/axios";
import { Link } from "react-router-dom";
import { _t } from "../../../helpers/helpers";
import {
  LazyLoadImage,
  trackWindowScroll,
} from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
const Brand = () => {
  const [brands, setBrands] = useState([]);
  useEffect(() => {
    ApiService.get("index/brands").then((resp) => {
      setBrands(resp.data.data);
      console.log(resp.data.data);
    });
  }, []);
  return (
    <div className="brand">
      <div className="container">
        <div className="brandHeader">
          <div className="title">{_t("sidebar.brands")}</div>
          <div className="customNav">
            <button className="custom_prev custom_prev3">
              <Arrow />
            </button>
            <button className="custom_next custom_next3">
              <Arrow />
            </button>
          </div>
        </div>
        <div className="row">
          {brands.map((brand) => {
            return (
              <div className="xl-2 lg-2 md-2 sm-6">
                <Link
                  to={`/products?brand=${brand.alias}`}
                  className="brandCard"
                >
                  <LazyLoadImage
                    src={brand.title_image}
                    alt="category"
                    effect="blur"
                  />
                </Link>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default trackWindowScroll(Brand);
