import React, { useEffect, useState } from "react";
import "../index.scss";
import SupportSidebar from "../../../components/SupportSidebar";
import ApiService from "../../../api/axios";
import Meta from "../../../components/Meta/Meta";
import BreadCrumb from "../../../components/BreadCrumb";
import { _t } from "../../../helpers/helpers";

const Privacy = () => {
  const [breadcrumbs, setBreadcrumbs] = useState([]);
  const [pageMeta, setPageMeta] = useState([]);
  const [terms, setTerms] = useState({});

  useEffect(() => {
    ApiService.get("pageByUrl?url=terms-and-conditions").then((resp) => {
      setPageMeta(resp.data.page_meta);
      setBreadcrumbs(resp.data.page_meta.breadcrumbs);
      setTerms(resp.data.data);
    });
  }, []);
  return (
    <div className="support">
      <div className="accoutRow">
        <div className="accountSidebar">
          <div className="accountSideBarChild">
            <Meta meta={pageMeta} />
            <BreadCrumb breadcrumbs={breadcrumbs} />
            <h1>{_t("title.support")}</h1>
            <SupportSidebar />
          </div>
        </div>
        <div className="AccountRight">
          <div className="accountRightInner">
            <div className="subTitle">{terms.title}</div>
            <div className="bonusList">
              <div
                dangerouslySetInnerHTML={{
                  __html: terms.content,
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Privacy;
