import React, { useEffect, useState, useContext } from "react";
import "./Footer.scss";
import { ReactComponent as FooterLogo } from "../../assets/footerLogo.svg";
import { ReactComponent as Call } from "../../assets/call.svg";
import { ReactComponent as Tel } from "../../assets/send.svg";
import { ReactComponent as Mail } from "../../assets/mail.svg";
import { ReactComponent as Instagram } from "../../assets/instagram.svg";
import { ReactComponent as Youtube } from "../../assets/youtube.svg";
import { ReactComponent as Facebook } from "../../assets/facebook.svg";
import { ReactComponent as User } from "../../assets/User.svg";
import { Link } from "react-router-dom";
import ApiService from "../../api/axios";
import { tokenContext } from "../Store";
import Swal from "sweetalert2";
import { _t } from "../../helpers/helpers";

const Footer = ({ settings }) => {
  const [footerList, setFilterList] = useState([]);
  const [token, setToken] = useContext(tokenContext);
  const [subscribe, setSubscribe] = useState("");
  const [footerCategory, setFooterCategory] = useState([
    {
      sub_menu: [],
    },
  ]);
  useEffect(() => {
    ApiService.get("helpers/menu/footer_menu").then((resp) => {
      setFilterList(resp.data.data);
    });
    ApiService.get("categories/list").then((res) => {
      setFooterCategory(res.data.category);
    });
  }, []);
  const onSubmit = (e) => {
    e.preventDefault();
    ApiService.post("/form/subscribe", { email: subscribe }).then((res) => {
      Swal.fire(res.data.title, res.data.message, "success");
    });
  };
  return (
    <footer>
      <div className="footer-inner">
        <div className="container">
          <div className="footerTop">
            <div className="row">
              <div className="xl-3 lg-3 md-3 sm-12">
                <Link to="/" className="footer-logo">
                  <FooterLogo />
                </Link>
                <a
                  href={`tel:${settings.wp_link}`}
                  className="callButton resp-none"
                >
                  <Call />
                  {settings.number}
                </a>
              </div>
              <div className="xl-4 lg-4 md-4 sm-12 resp-none">
                <div className="footerSearch">
                  <label htmlFor="">{_t("forms.subscribe")}</label>
                  <form action="" onSubmit={onSubmit}>
                    <Mail />
                    <input
                      type="text"
                      placeholder={_t("forms.emails")}
                      onChange={(e) => setSubscribe(e.target.value)}
                    />
                    <button>
                      <Tel />
                    </button>
                  </form>
                </div>
              </div>
              <div className="xl-5 lg-5 md-5 sm-12 resp-none">
                <div className="socialIconsFooter">
                  <a href={settings.instagram} target="_blank">
                    <Instagram />
                  </a>
                  <a href={settings.youtube} target="_blank">
                    <Youtube />
                  </a>
                  <a href={settings.facebook} target="_blank">
                    <Facebook />
                  </a>
                  {token ? (
                    <Link to="/account/personal-info" className="footerLogin">
                      <User />
                      <span>{_t("pages.personal_info.title")}</span>
                    </Link>
                  ) : (
                    <Link to="/login" className="footerLogin">
                      <User />
                      {_t("buttons.login")}
                    </Link>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="footerMiddle">
            <div className="row">
              {footerList?.map((footerItem) => {
                return (
                  <div className="xl-2 lg-2 md-2 sm-6" key={footerItem.id}>
                    <ul className="footer-ul">
                      <li className="title">{footerItem.title}</li>
                      {footerItem.sub_menus?.map((sub_menu) => {
                        return (
                          <li key={sub_menu.id}>
                            <Link to={`/${sub_menu.path}`}>
                              {sub_menu.title}
                            </Link>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                );
              })}
              {footerCategory?.map((headCat, index) => {
                return (
                  <div className="xl-2 lg-2 md-2 sm-12 resp-none" key={index}>
                    <ul className="footer-ul">
                      <li className="title">
                        <Link to={`/products/category/${headCat.alias}`}>
                          {headCat.title}
                        </Link>
                      </li>

                      {headCat.sub_menu?.map((sub) => {
                        return (
                          <li key={sub.id}>
                            <Link
                              to={`/products/category/${headCat.alias}/${sub.alias}`}
                            >
                              {sub.title}
                            </Link>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                );
              })}

              <div className="xl-4 lg-4 md-4 sm-12 desk-none">
                <div className="footerSearch">
                  <label htmlFor="">{_t("forms.subscribe")}</label>
                  <form action="" onSubmit={onSubmit}>
                    <Mail />
                    <input
                      type="text"
                      placeholder={_t("forms.emails")}
                      onChange={(e) => setSubscribe(e.target.value)}
                    />
                    <button>
                      <Tel />
                    </button>
                  </form>
                </div>
              </div>
              <div className="xl-5 lg-5 md-5 sm-12 desk-none">
                <div className="socialIconsFooter">
                  <a href={settings.instagram} target="_blank">
                    <Instagram />
                  </a>
                  <a href={settings.youtube} target="_blank">
                    <Youtube />
                  </a>
                  <a href={settings.facebook} target="_blank">
                    <Facebook />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footerBottom">
          © Toolino
          <span>{_t("footer.copyright")}</span>
          <span>{_t("footer.siteby")}</span>
          <a href="https://onestudio.az/" target="_blank">
            One Studio
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
