import React, { useState } from "react";
import AccountSidebar from "../../../components/AccountSideBar";
import BreadCrumb from "../../../components/BreadCrumb";
import "./Address.scss";
import ApiService from "../../../api/axios";
import Meta from "../../../components/Meta/Meta";
import AddressList from "./addressCard";
import { _t } from "../../../helpers/helpers";
import { useEffect } from "react";
const Address = () => {
  const [pageMeta, setPageMeta] = useState({});
  const [breadcrumbs, setBreadcrumbs] = useState([]);

  useEffect(() => {
    ApiService.get("account/address/list").then((resp) => {
      setPageMeta(resp.data.page_meta);
      setBreadcrumbs(resp.data.page_meta.breadcrumbs);
    });
  }, []);

  return (
    <div className="favorites account">
      <div className="accoutRow">
        <div className="accountSidebar">
          <div className="accountSideBarChild">
            <Meta meta={pageMeta} />
            <BreadCrumb breadcrumbs={breadcrumbs} />
            <h1>{_t("title.profile.info")}</h1>
            <AccountSidebar />
          </div>
        </div>
        <div className="AccountRight">
          <div className="accountRightInner">
            <h5>{_t("sidebar.address.title")}</h5>
            <p>{_t("account_content")}</p>
            <AddressList />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Address;
