import React, { useContext, useEffect, useState } from "react";
import InputMask from "react-input-mask";
import { QuantityPicker } from "react-qty-picker";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Rating } from "react-simple-star-rating";
import "react-tabs/style/react-tabs.css";
import SimpleReactValidator from "simple-react-validator";
import Swal from "sweetalert2";
import * as uuid from "uuid";
import ApiService from "../../api/axios";
import { ReactComponent as Trash } from "../../assets/Trash.svg";
import { ReactComponent as CartIcon } from "../../assets/cartIcon.svg";
import { ReactComponent as Manat } from "../../assets/manatBlack.svg";
import { ReactComponent as CartSvg } from "../../assets/none.svg";
import BreadCrumb from "../../components/BreadCrumb";
import Meta from "../../components/Meta/Meta";
import {
  cartItemsContext,
  quantityContext,
  tokenContext,
  totalContext,
} from "../../components/Store";
import { _t } from "../../helpers/helpers";
import { addToCart, deleteCart, initialCartData } from "../../store/cartSlice";
import AddressList from "../Account/Address/addressCard";
import "./Cart.scss";

const Cart = (props) => {
  const [cartPopup, setCartPopup] = useState(false);
  const [, setCartPopupError] = useState(false);

  const [cartItems, setCartItems] = useContext(cartItemsContext);
  const [quantity, setQuantity] = useContext(quantityContext);
  const [token] = useContext(tokenContext);
  const [total, setTotal] = useContext(totalContext);
  const [cartList, setCartList] = useState([]);
  const [, setTotalSale] = useState(0);
  const [full_name, setFullname] = useState("");
  const [city, setCity] = useState("Baku");
  const [full_address, setFullAddress] = useState("");
  const [note, setNote] = useState("");
  const [phone, setPhone] = useState("");
  const [payment, setPayment] = useState("cash");
  const [save_data, setSaveData] = useState(false);
  const [guest_uid] = useState("");
  const [postAddress, setPostAddress] = useState(0);
  const [breadcrumbs, setBreadcrumbs] = useState([]);
  const [pageMeta, setPageMeta] = useState([]);
  const [isCorporate, setIsCorporative] = useState("");
  let validator = new SimpleReactValidator();
  const [isBonus, setIsBonus] = useState("");
  const [isBonusFull, setIsBonusFull] = useState("");
  const [isBonusUse, setIsBonusUse] = useState(false);
  const [refetchCheckSum, setRefetchCheckSum] = useState(uuid.v4());
  const [alert] = useState({
    400: "Hesabınızda kifayyət qədər vəsait yoxdur",
    402: "Kartınızın istifadə müddəti bitmişdir",
    522: "Sessianız bitmişdir",
    500: "bilinməyən xəta",
  });
  const [, setPopupTitle] = useState("");
  const [, setPopupMessage] = useState("");
  const [deliveryFee, setDeliveryFee] = useState(0);

  const cart = useSelector((state) => state.cart);
  const dispatch = useDispatch();

  useEffect(() => {
    getDeliveryFee();
    init();
    orderAccepted();
    orderError();
  }, []);

  const getDeliveryFee = () => {
    ApiService.get("/helpers/settings").then((res) => {
      setDeliveryFee(res.data.data.delivery_fee);
    });
  };

  const init = () => {
    if (localStorage.token) {
      ApiService.get("/cart/user/list").then((res) => {
        setCartList(res.data.data);
        setQuantity(res.data.total_count);
        setTotal(res.data.total_price);
        setTotalSale(res.data.total_sale);
        setPageMeta(res.data.meta);
        setBreadcrumbs(res.data.meta.breadcrumbs);
        dispatch(initialCartData(res.data.data));
        res.data.data.map((item) => {
          if (
            document.querySelector(
              `#basket_product_${item.id} .quantity-display`
            )
          ) {
            console.log(
              document.querySelector(
                `#basket_product_${item.id} .quantity-display`
              )
            );
            document.querySelector(
              `#basket_product_${item.id} .quantity-display`
            ).value = item.count;
          }
        });
      });
    } else {
      ApiService.get("/cart/guest/list", {
        params: { guest_uid: localStorage.guest_uid },
      }).then((res) => {
        console.log(res.data.data);
        setCartList(res.data.data);
        setQuantity(res.data.total_count);
        setTotal(res.data.total_price);
        setTotalSale(res.data.total_sale);
        setPageMeta(res.data.meta);
        setBreadcrumbs(res.data.meta.breadcrumbs);
        dispatch(initialCartData(res.data.data));
        res.data.data.map((item) => {
          if (
            document.querySelector(
              `#basket_product_${item.id} .quantity-display`
            )
          ) {
            console.log(
              document.querySelector(
                `#basket_product_${item.id} .quantity-display`
              )
            );
            document.querySelector(
              `#basket_product_${item.id} .quantity-display`
            ).value = item.count;
          }
        });
      });
    }
  };

  const orderAccepted = () => {
    const order_id = new URLSearchParams(props.location.search).get("order_id");
    if (order_id) {
      ApiService.post("order/checkOrder", { order_id: "#" + order_id }).then(
        (_) => {
          resetBasket();
          props.history.push("/");
        }
      );
    }
  };
  const orderError = () => {
    const code = new URLSearchParams(props.location.search).get("code");
    const status = new URLSearchParams(props.location.search).get("status");
    if (status === "error" && code) {
      Swal.fire({
        icon: "error",
        title: "Xəta",
        text: alert[code],
        showConfirmButton: false,
        timer: 2000,
      });
    }
  };

  const resetBasket = () => {
    // Swal.fire({
    //   title: "Are you sure?",
    //   icon: "warning",
    //   showCancelButton: true,
    //   confirmButtonColor: "#3085d6",
    //   cancelButtonColor: "#d33",
    //   confirmButtonText: "OK",
    // }).then(() => {

    // });
    setCartItems([]);
    setQuantity(0);
    init();
    localStorage.setItem("quantity", 0);
    localStorage.setItem("cartItems", []);
  };
  const getCartProduct = () => {
    if (validator.allValid()) {
      const filtered_items = cartItems.filter(function (el) {
        return el != null;
      });
      console.log(token);
      if (token) {
        if (payment === "online") {
          Swal.fire({
            title: _t("order.question.title"),
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "OK",
          }).then((result) => {
            if (result.isConfirmed) {
              ApiService.post("order/user/checkout", {
                address_id: postAddress,
                note,
                payment_type: payment,
                items: filtered_items,
                is_bonus_paid: isBonusUse,
              }).then((res) => {
                resetBasket();
                window.location.href = res.data.url;
              });
            }
          });
        } else {
          Swal.fire({
            title: _t("order.question.title"),
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "OK",
          }).then((result) => {
            if (result.isConfirmed) {
              ApiService.post("order/user/checkout", {
                address_id: postAddress,
                note,
                payment_type: payment,
                items: filtered_items,
                is_bonus_paid: isBonusUse,
              }).then((res) => {
                resetBasket();
                setPopupTitle(res.data.title);
                setPopupMessage(res.data.message);
                resetBasket();
                setCartPopup(!cartPopup);
                setTimeout(() => {
                  props.history.push("/");
                }, 3000);
              });
            }
          });
        }
      } else {
        let guest_uid = localStorage.guest_uid;
        let order_fields = {
          address_info: {
            full_address,
            city,
          },
          user_info: {
            full_name,
            phone,
          },
          items: filtered_items,
          note,
          payment_type: payment,
          save_data,
          guest_uid,
        };
        Swal.fire({
          title: _t("warning.delete_question"),
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "OK",
        }).then((result) => {
          if (result.isConfirmed) {
            ApiService.post("order/guest/checkout", order_fields).then((_) => {
              localStorage.removeItem("guest_uid");
              ApiService.get("cart/guest/uid/generate").then((resp) => {
                localStorage.setItem("guest_uid", resp.data.uid);
              });
              resetBasket();
              setCartPopup(!cartPopup);
              setTimeout(() => {
                props.history.push("/");
              }, 3000);
            });
          }
        });
      }
    } else {
      validator.showMessages();
    }
  };

  useEffect(() => {
    ApiService.get("account").then((resp) => {
      setIsBonus(resp.data.user.bonus);
      setIsBonusFull(resp.data.user.bonusFull);
      setIsBonusUse(false);
      setIsCorporative(resp.data.user.type);
    });

    document.addEventListener("click", function () {
      if (document.querySelectorAll(".quantity-display")) {
        document.querySelectorAll(".quantity-display").forEach((item) => {
          reFetch(item);
        });
      }
    });
  }, []);

  const reFetch = (e) => {
    if (typeof e.value === "string" && Number.isNaN(parseFloat(e.value))) {
      setRefetchCheckSum(uuid.v4());
      setTimeout(init, 500);
    }
  };

  const selectedAddress = (data) => {
    setPostAddress(data);
  };
  const handleDelete = (id) => {
    let newList = cartList.filter((item) => item.id !== id);
    setCartList(newList);
    localStorage.token
      ? ApiService.get("cart/user/price").then((res) => {
          setTotal(res.data.total_price);
        })
      : ApiService.get("cart/guest/price", {
          params: { guest_uid: localStorage.guest_uid },
        }).then((res) => {
          setTotal(res.data.total_price);
        });
  };

  const closeButton = () => {
    setCartPopup(false);
    setCartPopupError(false);
    document.body.classList.remove("body-overflow");
  };

  const parsePrice = (price) => {
    if (typeof price === "string") {
      return parseFloat(price?.replace(/\,/g, ""));
    }
    return price;
  };

  const calculateTotalNew = (total) => {
    let price;
    const parsedTotal = parsePrice(total);
    if (isBonusUse) {
      if (parsedTotal - parseFloat(isBonusFull) >= 0) {
        if (parsedTotal - parseFloat(isBonusFull) > 99) {
          price = parsedTotal - parseFloat(isBonus);
        } else {
          if (isCorporate == "user") {
            price = parsedTotal - parseFloat(isBonus) + deliveryFee;
          } else {
            price = parsedTotal - parseFloat(isBonus);
          }
        }
      } else {
        price = deliveryFee;
      }
    } else {
      if (parsePrice(total) >= 100) {
        price = parsedTotal;
      } else {
        if (isCorporate == "user") {
          price = parsedTotal + deliveryFee;
        } else {
          price = parsedTotal;
        }
      }
    }

    return price.toFixed(2);
  };

  const calculateTotal = () => {
    let price;
    const parsedTotal = parsePrice(total);
    if (isBonusUse) {
      if (parsedTotal - parseFloat(isBonusFull) >= 0) {
        if (parsedTotal - parseFloat(isBonusFull) > 99) {
          price = parsedTotal - parseFloat(isBonus);
        } else {
          price = parsedTotal - parseFloat(isBonus) + deliveryFee;
        }
      } else {
        price = deliveryFee;
      }
    } else {
      if (parsePrice(total) >= 100) {
        price = parsedTotal;
      } else {
        price = parsedTotal + deliveryFee;
      }
    }
    return price.toFixed(2);
  };

  const quantityOnChange = (newValue, item) => {
    if (typeof newValue === "string" && Number.isNaN(parseFloat(newValue)))
      return;
    if (newValue == 0) {
      Swal.fire({
        title: _t("warning.delete_question"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "OK",
      }).then((result) => {
        if (result.isConfirmed) {
          dispatch(deleteCart(item.id));
        } else {
          setRefetchCheckSum(uuid.v4());
          localStorage.token
            ? ApiService.get("cart/user/list").then((res) => {
                dispatch(initialCartData(res.data.data));
                // setTotal(res.data.total_price);
                // setQuantity(res.data.total_count);
                // setCartList(res.data.data)
              })
            : ApiService.get("cart/guest/list", {
                params: {
                  guest_uid: localStorage.guest_uid,
                },
              }).then((res) => {
                dispatch(initialCartData(res.data.data));
                // setTotal(res.data.total_price);
                // setQuantity(res.data.total_count);
                // setCartList(res.data.data)
              });
        }
      });
    } else {
      dispatch(addToCart({ ...item, count: newValue }));
    }
  };

  const handleDeleteCartItem = (item) => {
    Swal.fire({
      title: _t("warning.delete_question"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "OK",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(deleteCart(item.id));
      }
    });
  };

  const calculateRemainingBonus = () => {
    if (isBonusUse) {
      return parseFloat(isBonusFull) - calculateTotalNew(cart.total) > 0
        ? isBonusFull
        : "0.00";
    } else {
      return isBonusFull;
    }
  };

  return (
    <div className="cart">
      <Meta meta={pageMeta} />
      <div className="container">
        <BreadCrumb breadcrumbs={breadcrumbs} />
      </div>
      {cartPopup ? (
        <div className="popup cartPopup">
          <div className="cartPopupContent">
            <div className="closeButton" onClick={() => closeButton()}>
              <svg
                width="26"
                height="26"
                viewBox="0 0 26 26"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1.00391 1.00293L25.0039 25.0029M1.00391 25.0029L25.0039 1.00293"
                  stroke="#BDBBBB"
                  strokeWidth="2"
                />
              </svg>
            </div>
            <h2>{_t("message.order_placed")}</h2>
            <p>{_t("order.success_message")}</p>
          </div>
        </div>
      ) : null}
      {cart.products.length > 0 ? (
        <div className="cart-row">
          <div className="cart-left">
            <div className="card-left-inner">
              {/* <BreadCrumb link="/cart" linkName="Səbət" /> */}
              <div className="title">
                {_t("pages.cart.title")}
                <span>
                  ({cart.quantity} {_t("sidebar.product")})
                </span>
              </div>
              <div className="cartSection">
                {cart.products.map((item, key) => {
                  return (
                    <div
                      className="cartCard"
                      id={`basket_product_${item.id}`}
                      key={`${item.id}-${refetchCheckSum}`}
                    >
                      {item.sale_percent <= 0 ? null : (
                        <div className="discount">-{item.sale_percent}%</div>
                      )}
                      <button
                        type="button"
                        className="trash"
                        onClick={() => {
                          handleDeleteCartItem(item);
                        }}
                      >
                        <Trash />
                      </button>
                      <div className="img-container">
                        <Link
                          to={`/products/${item.category.alias}/${item.alias}`}
                        >
                          <img src={item.title_image} alt="productImg" />
                        </Link>
                      </div>
                      <div className="cartCardDetail">
                        <Link
                          to={`/products/${item.category.alias}/${item.alias}`}
                        >
                          <h2>{item.title}</h2>
                        </Link>
                        <div className="rating">
                          <Rating
                            fillColor={"#FF0852"}
                            readonly={true}
                            allowHalfIcon={true}
                            iconsCount={5}
                            size={20}
                            ratingValue={props.rating * 20}
                          />
                        </div>
                        <div className="cartCardDetailBottom">
                          <div className="priceCard">
                            {item.sale_price != 0 &&
                            item.sale_price != item.price ? (
                              <>
                                {item.sale_price}
                                <Manat />
                              </>
                            ) : (
                              ""
                            )}
                            {item.sale_price != 0 &&
                            item.sale_price != item.price ? (
                              <span>
                                {item.price}
                                <Manat />
                              </span>
                            ) : (
                              <>
                                {item.price}
                                <Manat />
                              </>
                            )}
                          </div>
                          <div className="quantity">
                            <QuantityPicker
                              value={item.count}
                              disabled
                              max={9999}
                              min={0}
                              onChange={(newValue) =>
                                quantityOnChange(newValue, item)
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
              <div className="cartTotalParent">
                <div className="cartTotal">
                  <div className="CarttotalPrice">
                    {isCorporate == "corporate" ? (
                      <p>
                        {_t("title.delivery")}:
                        <span>
                          0 <Manat />
                        </span>
                      </p>
                    ) : (
                      <>
                        {parsePrice(cart.total) > parseInt(99.99) ? (
                          <p>
                            {_t("title.delivery")}:
                            <span>
                              0 <Manat />
                            </span>
                          </p>
                        ) : (
                          <p>
                            {_t("title.delivery")}:
                            <span>
                              {deliveryFee} <Manat />
                            </span>
                          </p>
                        )}
                      </>
                    )}
                    {isBonusUse === true ? (
                      <p>
                        {_t("title.cart.bonus")} :
                        <span>
                          {parseFloat(isBonusFull) - parsePrice(cart.total) > 0
                            ? parsePrice(cart.total).toFixed(2)
                            : parseFloat(isBonusFull).toFixed(2)}
                          <Manat />
                        </span>
                      </p>
                    ) : null}
                    <p>
                      {_t("title.total")}
                      <span>
                        {cart.total}
                        <Manat />
                      </span>
                    </p>
                  </div>
                  <div className="cartTotalChild">
                    <p>
                      {_t("title.conclusion")}
                      <span>
                        {/* {calculateTotal()} */}
                        {calculateTotalNew(cart.total)}
                        {/* {parseFloat(cart.total) > 99.99 ? cart.total : (parseFloat(cart.total)  + 10).toFixed(2) } */}
                        <Manat />
                      </span>
                    </p>
                  </div>
                </div>
                {token && isBonus > 0 ? (
                  <label htmlFor="" className="filterCheckbox bonusCheckbox">
                    <span>
                      {_t("title.cart.bonus")} - {calculateRemainingBonus()}
                    </span>
                    <input
                      type="checkbox"
                      name="bonus"
                      id="bonus"
                      onChange={(e) => {
                        setIsBonusUse(!isBonusUse);
                      }}
                    />
                    <span className="checkmark"></span>
                  </label>
                ) : null}
                <div className="orderComplated">
                  <Link to="/products">{_t("cart.back_to_shop")}</Link>
                  <button
                    className="green-button resp-none"
                    onClick={getCartProduct}
                  >
                    {_t("button.complate.order")}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="cart-right">
            {token ? (
              <div className="cart-right-inner">
                <form className="newPerson newPersonLogin">
                  <h2>1.{_t("title.delivery")}</h2>
                  <AddressList selected={selectedAddress} />

                  <div className="input-group">
                    <textarea
                      name="note"
                      id="note"
                      onChange={(e) => setNote(e.target.value)}
                      cols="30"
                      rows="10"
                      placeholder={_t("title.note")}
                    ></textarea>
                  </div>

                  <h2>
                    2. {_t("table.header.payment_type")}
                    <span>
                      {/* ( {_t("title.delivery")}: {_t("title.free")}) */}
                    </span>
                  </h2>
                  <label htmlFor="" className="filterRadio">
                    <span>{_t("title.cash.payment")}</span>
                    <input
                      type="radio"
                      value="cash"
                      onChange={(e) => setPayment("cash")}
                      name="payment"
                      checked={payment === "cash"}
                      id=""
                    />
                    <span className="checkmark"></span>
                  </label>
                  {isCorporate == "user" ? (
                    <label htmlFor="" className="filterRadio">
                      <span>{_t("title.online.payment")}</span>
                      <input
                        type="radio"
                        value="online"
                        onChange={(e) => {
                          setPayment("online");
                        }}
                        checked={payment === "online"}
                        name="payment"
                        id=""
                      />
                      <span className="checkmark"></span>
                    </label>
                  ) : null}
                </form>

                <button
                  className="green-button desk-none"
                  onClick={getCartProduct}
                >
                  {_t("button.complate.order")}
                </button>
              </div>
            ) : (
              <div className="cart-right-inner">
                <div className="newPerson">
                  <h2>1. {_t("title.profile.info")}</h2>
                  <div className="input-group user">
                    <div className="input-child">
                      <input
                        type="text"
                        name="full_name"
                        onChange={(e) => setFullname(e.target.value)}
                        placeholder={_t("forms_name_surname")}
                      />
                    </div>
                  </div>
                  <div className="input-group phone">
                    <div className="input-child">
                      <InputMask
                        mask="+\9\9\4999999999"
                        maskChar=" "
                        name="phone"
                        placeholder={_t("forms.numbers")}
                        onChange={(e) => setPhone(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                <div className="newPerson">
                  <h2>2. {_t("title.delivery")}</h2>
                  <div className="input-group loc">
                    <div className="input-child">
                      <input
                        type="text"
                        placeholder={_t("forms.city")}
                        name="address[city]"
                        onChange={(e) => setCity(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="input-group loc">
                    <div className="input-child">
                      <input
                        type="text"
                        placeholder={_t("forms.address")}
                        onChange={(e) => setFullAddress(e.target.value)}
                        name="address[full_address]"
                      />
                    </div>
                  </div>
                  <div className="input-group">
                    <textarea
                      name="note"
                      id="note"
                      onChange={(e) => setNote(e.target.value)}
                      cols="30"
                      rows="10"
                      placeholder={_t("title.note")}
                    ></textarea>
                  </div>
                </div>
                <div className="newPerson">
                  {/* <h2>
                    3. {_t("title.delivery")}
                    : <span>{_t("title.free")}</span>
                  </h2> */}
                  <label htmlFor="payment_cash_guest" className="filterRadio">
                    <span>{_t("title.cash.payment")}</span>
                    <input
                      type="radio"
                      value="cash"
                      onChange={(e) => setPayment("cash")}
                      name="payment"
                      checked={payment === "cash"}
                      id="payment_cash_guest"
                    />
                    <span className="checkmark"></span>
                  </label>
                  <label htmlFor="payment_online_guest" className="filterRadio">
                    <span>{_t("title.online.payment")}</span>
                    <input
                      type="radio"
                      value="online"
                      onChange={(e) => setPayment("online")}
                      checked={payment === "online"}
                      name="payment"
                      id="payment_online_guest"
                    />
                    <span className="checkmark"></span>
                  </label>
                  <div className="save-Info">
                    <label htmlFor="" className="filterCheckbox">
                      <span>{_t("title.info.save")} </span>
                      <input
                        type="checkbox"
                        onChange={(e) => setSaveData(!save_data)}
                        name="save_data"
                        id=""
                      />
                      <span className="checkmark"></span>
                    </label>
                    <div className="saveInfoChild">
                      <p>
                        "{_t("title.info.save")}" {_t("title.accept")}
                      </p>
                      <Link to="/support/terms">
                        {_t("title.user.agreement")}
                      </Link>
                    </div>
                  </div>
                  <button
                    className="green-button desk-none"
                    onClick={getCartProduct}
                  >
                    {_t("button.complate.order")}
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      ) : (
        <CartListEmptyComponent />
      )}
    </div>
  );
};

export default Cart;

const CartListEmptyComponent = () => {
  return (
    <div className="row">
      <div className="xl-12 lg-12 md-12 sm-12">
        <div className="cartEmpty">
          <div className="cartEptyContent">
            <Link to="/">
              <CartSvg />
            </Link>
            <p>
              {_t("cart.empty")}, {_t("cart.back_to_shop")}
            </p>
            <Link to="/products" className="redButton cartEmptyButton">
              <CartIcon />
              {_t("cart.back_to_shop")}
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};
