import ApiService from "../api/axios";
import { _t } from "./helpers";
import Swal from "sweetalert2";
export const addTosWishlist = (e, id) => {
  ApiService.post("account/addWishList", { product_id: id }).then((resp) => {
    if (e.target.checked) {
      e.preventDefault();
      localStorage.setItem("wishlist", resp.data.wishlist);
      Swal.fire({
        position: "center",
        icon: "success",
        title: _t("message.wishlist_added"),
        showConfirmButton: false,
        timer: 1000,
      });
    } else {
      localStorage.setItem("wishlist", resp.data.wishlist);

      Swal.fire({
        position: "center",
        icon: "success",
        title: _t("message.wishlist_deleted"),
        showConfirmButton: false,
        timer: 1000,
      });
    }
  });
};
export const addToCompare = (e, id) => {
  ApiService.post("account/addCompare", { product_id: id }).then((resp) => {
    if (e.target.checked) {
      e.preventDefault();
      localStorage.setItem("comparelist", resp.data.compare);
      Swal.fire({
        position: "center",
        icon: "success",
        title: _t("message.compare_added"),
        showConfirmButton: false,
        timer: 1000,
      });
      // window.location.reload();
    } else {
      localStorage.setItem("comparelist", resp.data.compare);
      Swal.fire({
        position: "center",
        icon: "success",
        title: _t("message.comparelist_deleted"),
        showConfirmButton: false,
        timer: 1000,
      });
    }
  });
};
