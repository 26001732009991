import React, { useState } from "react";
import Swal from "sweetalert2";
import ApiService from "../../../api/axios";
import { ReactComponent as Lock } from "../../../assets/lock.svg";
import { _t } from "../../../helpers/helpers";
import "../Authentification.scss";

const ResetPassword = (props) => {
  const [eye, seteye] = useState(true);
  const [passwordType, setPasswordType] = useState("password");
  const [eyeConfirm, setEyeConfirm] = useState(true);
  const [passwordTypeConfirm, setPasswordTypeConfirm] = useState("password");

  const Eye = () => {
    if (passwordType == "password") {
      setPasswordType("text");
      seteye(false);
    } else {
      setPasswordType("password");
      seteye(true);
    }
  };
  const EyeConfirm = () => {
    if (passwordTypeConfirm == "password") {
      setPasswordTypeConfirm("text");
      setEyeConfirm(false);
    } else {
      setPasswordTypeConfirm("password");
      setEyeConfirm(true);
    }
  };

  const [new_password, setPassword] = useState("");
  const [new_password_confirmation, setPassword_confirmation] = useState("");
  const [otp, setOtp] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();

    ApiService.post("auth/code/check", {
      password: new_password,
      password_confirmation: new_password_confirmation,
      code: otp,
    }).then((resp) => {
      localStorage.setItem("token", resp.data.token);
      localStorage.setItem("wishlist", resp.data.user.wishlist);
      localStorage.setItem("full_name", resp.data.user.fullname);
      Swal.fire(resp.data.title, resp.data.message, "success");
      props.history.push("/");
      window.location.reload();
    });
  };

  return (
    <div className="auth login">
      <div className="container">
        {/* <Breadcrumb
          link="/login"
          linkName="login"
          className="active"
          secondLink="/forget-password/"
          secondLinkName="Şifrəni unutmuşam"
        /> */}
        <div className="row">
          <div className="xl-3 lg-3 md-12 sm-12"></div>
          <div className="xl-6 lg-6 md-12 sm-12">
            <div className="authContainer">
              <div className="title">{_t("pages.change_password.title")}</div>
              <form onSubmit={handleSubmit} className="authContainerBody">
                <div className="input-group phone">
                  <input
                    type="number"
                    placeholder={_t("buttons_otp_code")}
                    onChange={(e) => setOtp(e.target.value)}
                  />
                </div>
                <div className="input-group password">
                  <div className="input-child">
                    <input
                      type={passwordType}
                      placeholder={_t("forms.new_password")}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                    <div className="eye" onClick={Eye}>
                      {eye ? (
                        <svg
                          width="17"
                          height="12"
                          viewBox="0 0 17 12"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M1.98438 6C1.98438 5.73273 2.09051 5.29799 2.36143 4.77784C2.62767 4.26665 3.03764 3.7034 3.60477 3.1799C4.73302 2.13844 6.48759 1.25 8.98438 1.25C11.4812 1.25 13.2357 2.13844 14.364 3.1799C14.9311 3.7034 15.3411 4.26665 15.6073 4.77784C15.8782 5.29799 15.9844 5.73273 15.9844 6C15.9844 6.26727 15.8782 6.70201 15.6073 7.22216C15.3411 7.73335 14.9311 8.2966 14.364 8.8201C13.2357 9.86156 11.4812 10.75 8.98438 10.75C6.48759 10.75 4.73302 9.86156 3.60477 8.8201C3.03764 8.2966 2.62767 7.73335 2.36143 7.22216C2.09051 6.70201 1.98438 6.26727 1.98438 6ZM8.98438 0.25C6.23116 0.25 4.23573 1.23656 2.92649 2.4451C2.27486 3.0466 1.7942 3.7021 1.47451 4.31591C1.15949 4.92076 0.984375 5.51727 0.984375 6C0.984375 6.48273 1.15949 7.07924 1.47451 7.68409C1.7942 8.2979 2.27486 8.9534 2.92649 9.5549C4.23573 10.7634 6.23116 11.75 8.98438 11.75C11.7376 11.75 13.733 10.7634 15.0423 9.5549C15.6939 8.9534 16.1745 8.2979 16.4942 7.68409C16.8093 7.07924 16.9844 6.48273 16.9844 6C16.9844 5.51727 16.8093 4.92076 16.4942 4.31591C16.1745 3.7021 15.6939 3.0466 15.0423 2.4451C13.733 1.23656 11.7376 0.25 8.98438 0.25ZM7.2343 6.00002C7.2343 5.03352 8.0178 4.25002 8.9843 4.25002C9.9508 4.25002 10.7343 5.03352 10.7343 6.00002C10.7343 6.96652 9.9508 7.75002 8.9843 7.75002C8.0178 7.75002 7.2343 6.96652 7.2343 6.00002ZM8.9843 3.25002C7.46552 3.25002 6.2343 4.48123 6.2343 6.00002C6.2343 7.5188 7.46552 8.75002 8.9843 8.75002C10.5031 8.75002 11.7343 7.5188 11.7343 6.00002C11.7343 4.48123 10.5031 3.25002 8.9843 3.25002Z"
                            fill="#121212"
                          />
                        </svg>
                      ) : (
                        <svg
                          width="16"
                          height="15"
                          viewBox="0 0 16 15"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M3.48805 6.66537C4.55252 7.84812 6.17738 8.99985 8.49139 8.99985C10.8054 8.99985 12.4303 7.84812 13.4948 6.66536C14.0273 6.0736 14.4133 5.48014 14.6658 5.03459C14.7917 4.81231 14.8837 4.62823 14.9434 4.50148C14.9733 4.43815 14.9951 4.38925 15.0089 4.3572C15.0159 4.34117 15.0209 4.32937 15.0239 4.32209L15.027 4.31461L15.0273 4.31382C15.0272 4.31397 15.0272 4.31415 15.4914 4.49985C15.9556 4.68554 15.9556 4.68576 15.9555 4.68599L15.9552 4.68656L15.9546 4.68803L15.9529 4.69233L15.9471 4.70629C15.9423 4.71793 15.9354 4.73421 15.9265 4.75481C15.9087 4.79599 15.8826 4.85452 15.848 4.9279C15.7788 5.07459 15.6755 5.28113 15.5358 5.52761C15.257 6.01956 14.8305 6.6761 14.238 7.33433C14.0552 7.53753 13.8559 7.74146 13.6397 7.94107L15.345 9.64629L14.6379 10.3534L12.863 8.57856C11.8552 9.30882 10.5739 9.88293 8.99139 9.98401L8.99141 11.9998L7.99141 11.9999L7.99139 9.98401C6.40886 9.88293 5.12761 9.30882 4.11979 8.57857L2.34496 10.3534L1.63785 9.64629L3.34307 7.94108C3.12692 7.74147 2.92763 7.53753 2.74475 7.33433C2.15234 6.67609 1.72579 6.01955 1.44702 5.5276C1.30735 5.28113 1.204 5.07459 1.13484 4.9279C1.10025 4.85452 1.07416 4.79599 1.05631 4.7548C1.04738 4.7342 1.04051 4.71793 1.03567 4.70629L1.02991 4.69233L1.02817 4.68803L1.02757 4.68655L1.02735 4.68599C1.02725 4.68576 1.02717 4.68554 1.49141 4.49985C1.95564 4.31415 1.95557 4.31398 1.95551 4.31382L1.95542 4.3136L1.95584 4.31461L1.95892 4.3221C1.96195 4.32937 1.96692 4.34117 1.97387 4.3572C1.98776 4.38925 2.00951 4.43815 2.03937 4.50148C2.09912 4.62823 2.19108 4.81231 2.31704 5.03459C2.56952 5.48014 2.95546 6.0736 3.48805 6.66537ZM1.95538 4.31349C1.95536 4.31345 1.95538 4.31349 1.95542 4.3136L1.95538 4.31349Z"
                            fill="black"
                          />
                        </svg>
                      )}
                    </div>
                  </div>
                </div>
                <div className="input-group password">
                  <div className="input-child">
                    <label htmlFor=""></label>

                    <input
                      type={passwordTypeConfirm}
                      placeholder={_t("forms.repeat_password")}
                      name="password_confirmation"
                      onChange={(e) => setPassword_confirmation(e.target.value)}
                    />

                    <div className="eye" onClick={EyeConfirm}>
                      {eyeConfirm ? (
                        <svg
                          width="17"
                          height="12"
                          viewBox="0 0 17 12"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M1.98438 6C1.98438 5.73273 2.09051 5.29799 2.36143 4.77784C2.62767 4.26665 3.03764 3.7034 3.60477 3.1799C4.73302 2.13844 6.48759 1.25 8.98438 1.25C11.4812 1.25 13.2357 2.13844 14.364 3.1799C14.9311 3.7034 15.3411 4.26665 15.6073 4.77784C15.8782 5.29799 15.9844 5.73273 15.9844 6C15.9844 6.26727 15.8782 6.70201 15.6073 7.22216C15.3411 7.73335 14.9311 8.2966 14.364 8.8201C13.2357 9.86156 11.4812 10.75 8.98438 10.75C6.48759 10.75 4.73302 9.86156 3.60477 8.8201C3.03764 8.2966 2.62767 7.73335 2.36143 7.22216C2.09051 6.70201 1.98438 6.26727 1.98438 6ZM8.98438 0.25C6.23116 0.25 4.23573 1.23656 2.92649 2.4451C2.27486 3.0466 1.7942 3.7021 1.47451 4.31591C1.15949 4.92076 0.984375 5.51727 0.984375 6C0.984375 6.48273 1.15949 7.07924 1.47451 7.68409C1.7942 8.2979 2.27486 8.9534 2.92649 9.5549C4.23573 10.7634 6.23116 11.75 8.98438 11.75C11.7376 11.75 13.733 10.7634 15.0423 9.5549C15.6939 8.9534 16.1745 8.2979 16.4942 7.68409C16.8093 7.07924 16.9844 6.48273 16.9844 6C16.9844 5.51727 16.8093 4.92076 16.4942 4.31591C16.1745 3.7021 15.6939 3.0466 15.0423 2.4451C13.733 1.23656 11.7376 0.25 8.98438 0.25ZM7.2343 6.00002C7.2343 5.03352 8.0178 4.25002 8.9843 4.25002C9.9508 4.25002 10.7343 5.03352 10.7343 6.00002C10.7343 6.96652 9.9508 7.75002 8.9843 7.75002C8.0178 7.75002 7.2343 6.96652 7.2343 6.00002ZM8.9843 3.25002C7.46552 3.25002 6.2343 4.48123 6.2343 6.00002C6.2343 7.5188 7.46552 8.75002 8.9843 8.75002C10.5031 8.75002 11.7343 7.5188 11.7343 6.00002C11.7343 4.48123 10.5031 3.25002 8.9843 3.25002Z"
                            fill="#121212"
                          />
                        </svg>
                      ) : (
                        <svg
                          width="16"
                          height="15"
                          viewBox="0 0 16 15"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M3.48805 6.66537C4.55252 7.84812 6.17738 8.99985 8.49139 8.99985C10.8054 8.99985 12.4303 7.84812 13.4948 6.66536C14.0273 6.0736 14.4133 5.48014 14.6658 5.03459C14.7917 4.81231 14.8837 4.62823 14.9434 4.50148C14.9733 4.43815 14.9951 4.38925 15.0089 4.3572C15.0159 4.34117 15.0209 4.32937 15.0239 4.32209L15.027 4.31461L15.0273 4.31382C15.0272 4.31397 15.0272 4.31415 15.4914 4.49985C15.9556 4.68554 15.9556 4.68576 15.9555 4.68599L15.9552 4.68656L15.9546 4.68803L15.9529 4.69233L15.9471 4.70629C15.9423 4.71793 15.9354 4.73421 15.9265 4.75481C15.9087 4.79599 15.8826 4.85452 15.848 4.9279C15.7788 5.07459 15.6755 5.28113 15.5358 5.52761C15.257 6.01956 14.8305 6.6761 14.238 7.33433C14.0552 7.53753 13.8559 7.74146 13.6397 7.94107L15.345 9.64629L14.6379 10.3534L12.863 8.57856C11.8552 9.30882 10.5739 9.88293 8.99139 9.98401L8.99141 11.9998L7.99141 11.9999L7.99139 9.98401C6.40886 9.88293 5.12761 9.30882 4.11979 8.57857L2.34496 10.3534L1.63785 9.64629L3.34307 7.94108C3.12692 7.74147 2.92763 7.53753 2.74475 7.33433C2.15234 6.67609 1.72579 6.01955 1.44702 5.5276C1.30735 5.28113 1.204 5.07459 1.13484 4.9279C1.10025 4.85452 1.07416 4.79599 1.05631 4.7548C1.04738 4.7342 1.04051 4.71793 1.03567 4.70629L1.02991 4.69233L1.02817 4.68803L1.02757 4.68655L1.02735 4.68599C1.02725 4.68576 1.02717 4.68554 1.49141 4.49985C1.95564 4.31415 1.95557 4.31398 1.95551 4.31382L1.95542 4.3136L1.95584 4.31461L1.95892 4.3221C1.96195 4.32937 1.96692 4.34117 1.97387 4.3572C1.98776 4.38925 2.00951 4.43815 2.03937 4.50148C2.09912 4.62823 2.19108 4.81231 2.31704 5.03459C2.56952 5.48014 2.95546 6.0736 3.48805 6.66537ZM1.95538 4.31349C1.95536 4.31345 1.95538 4.31349 1.95542 4.3136L1.95538 4.31349Z"
                            fill="black"
                          />
                        </svg>
                      )}
                    </div>
                  </div>
                </div>

                <button className="blackButton">
                  <Lock />
                  {_t("buttons.send")}
                </button>
                <span className="sendToEmail">
                  {_t("conifrm.code.content")}
                </span>
              </form>
            </div>
          </div>
          <div className="xl-3 lg-3 md-12 sm-12"></div>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
