import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./ProductDetail.scss";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import "swiper/css/pagination";

import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Thumbs, Pagination, A11y } from "swiper";

import { ReactComponent as Manat } from "../../assets/manatBlack.svg";
import { ReactComponent as Cart } from "../../assets/Cart.svg";
import { ReactComponent as Heart } from "../../assets/Heart.svg";
import { ReactComponent as Swap } from "../../assets/arrowSwap.svg";
import { ReactComponent as Fb } from "../../assets/fb.svg";
import { ReactComponent as Whatsapp } from "../../assets/whatsapp.svg";
import { ReactComponent as Cross } from "../../assets/Cross.svg";

import { ReactComponent as Share } from "../../assets/share.svg";
import { ReactComponent as Tw } from "../../assets/tw.svg";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import ApiService from "../../api/axios";
import { Comments, FacebookProvider, Share as FbShare } from "react-facebook";
import ShareLink from "react-twitter-share-link";
import {
  _t,
  addToCartItem,
  //   getTotalCount,
  //   getTotalPrice,
} from "../../helpers/helpers";
import "react-tabs/style/react-tabs.css";
import Meta from "../../components/Meta/Meta";
import BreadCrumb from "../../components/BreadCrumb";
import { QuantityPicker } from "react-qty-picker";
import {
  quantityContext,
  totalContext,
  tokenContext,
} from "../../components/Store";
import { Rating } from "react-simple-star-rating";
import { CopyToClipboard } from "react-copy-to-clipboard/src";
import ProductCard from "../../components/ProductCard";
import Swal from "sweetalert2";
import { addTosWishlist } from "../../helpers/functions";
import { addToCompare } from "../../helpers/functions";
import { ReactComponent as Arrow } from "../../assets/arrowRight.svg";
import category from "../../components/Homepage/Category";
import { addToCart } from "../../store/cartSlice";
import { useDispatch } from "react-redux";
import Disqus from "disqus-react";

const ProductDetail = (props) => {
  const [breadcrumbs, setBreadcrumbs] = useState([]);
  const [pageMeta, setPageMeta] = useState([]);
  const [token, setToken] = useContext(tokenContext);
  const [quantity, setQuantity] = useContext(quantityContext);
  const [count, setCount] = useState(1);
  const [buttonClass, setButtonClass] = useState("addToCard");
  const [total, setTotal] = useContext(totalContext);
  const [similars, setSimilar] = useState([]);
  const [quantites, setQuantites] = useState([]);
  const [outOfStock, setOutOfStock] = useState([]);
  const [productDetail, setProductDetail] = useState({
    title_image: {
      slider: "",
    },
    sticker: {
      color: "",
      title: "",
    },
    category: {},
    brand: {},
    attributes: {},
  });
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const [thumbsSwiperPopup, setThumbsSwiperPopup] = useState(null);
  //   const options = [
  //     {
  //       value: "Burada sizin məhsulun çeşidi yazılacaq",
  //       label: (
  //         <div className="filterInner">
  //           Burada sizin məhsulun çeşidi yazılacaq
  //         </div>
  //       ),
  //     },
  //     { value: "sarı", label: <div className="filterInner">Sarı</div> },
  //   ];
  //   const customStyles = {
  //     menu: (provided, state) => ({
  //       ...provided,
  //       background: " #F0F0F0",
  //       borderRadius: ".5rem",
  //       fontStyle: "normal",
  //       fontWeight: "600",
  //       fontSize: "1.3rem",
  //       lineHeight: "125.1%",
  //       color: "rgba(0, 0, 0, 0.65)",
  //       overflow: "hidden",
  //     }),
  //     control: () => ({
  //       background: " #F0F0F0",
  //       borderRadius: "4rem",
  //       fontStyle: "normal",
  //       fontWeight: "600",
  //       fontSize: "1.3rem",
  //       lineHeight: "125.1%",
  //       color: "rgba(0, 0, 0, 0.65)",
  //       display: "flex",
  //       alignItems: "center",
  //       maxWidth: "35rem",
  //       width: "35rem",
  //     }),
  //     option: (styles, state) => {
  //       return {
  //         ...styles,
  //       };
  //     },
  //     singleValue: (provided, state) => ({
  //       display: "flex",
  //       alignItems: "center",
  //     }),
  //   };
  useEffect(() => {
    ApiService.get(
      `products/getProductInfoByUrl/${props.match.params.subcategory}/${props.match.params.alias}`
    ).then((resp) => {
      setProductDetail(resp.data.data);
      setPageMeta(resp.data.page_meta);
      setBreadcrumbs(resp.data.page_meta.breadcrumbs);
      setCount(resp.data.data.in_basket_count);
      setSimilar(resp.data.similar_products);
      setQuantites([resp.data.data.id]);
      setOutOfStock(resp.data.data.out_of_stock);
      if (resp.data.data.in_basket) {
        setButtonClass("addToCard green-button");
      } else {
        setButtonClass("addToCard");
      }
    });
  }, [props.match.params.alias]);
  console.log(outOfStock);

  const persistScrollPosition = (id) => {
    sessionStorage.setItem("scroll-position-product-id-marker", id);
  };

  const [productMarkerId] = React.useState(
    sessionStorage.getItem("scroll-position-product-id-marker")
  );

  const dispatch = useDispatch();

  const disqusShortname = "toolino";
  const disqusConfig = {
    url: `{products/getProductInfoByUrl/${props.match.params.subcategory}/${props.match.params.alias}`,
    identifier: `{products/getProductInfoByUrl/${props.match.params.subcategory}/${props.match.params.id}`,
    title: "Toolino",
  };

  const [modal, setModal] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  window.addEventListener("resize", () => {
    setIsMobile(window.innerWidth < 768);
  });

  return (
    <div className="productDetail">
      <Meta meta={pageMeta} />

      <div className="container">
        <BreadCrumb breadcrumbs={breadcrumbs} />
        <div className="row productRow">
          <div className="xl-6 lg-6 md-6 sm-12">
            <div className="productDetailImg">
              {productDetail.sale_percent <= 0 ? null : (
                <div className="discount">-{productDetail.sale_percent}%</div>
              )}
              <Swiper
                style={{
                  "--swiper-navigation-color": "#fff",
                  "--swiper-pagination-color": "#fff",
                }}
                slidesPerView={1}
                spaceBetween={10}
                navigation={true}
                thumbs={{ swiper: thumbsSwiper }}
                modules={[Navigation, Thumbs, Pagination, A11y]}
                className="mySwiper2"
                pagination={{ clickable: true }}
                breakpoints={{
                  320: {
                    slidesPerView: 1,
                  },
                }}
              >
                <SwiperSlide>
                  {isMobile ? (
                    <div
                      className="productDetailSwiper"
                      onClick={() => setModal(true)}
                    >
                      <img src={productDetail.title_image} alt="product img" />
                    </div>
                  ) : (
                    <div className="productDetailSwiper">
                      <img src={productDetail.title_image} alt="product img" />
                    </div>
                  )}
                </SwiperSlide>
                {productDetail.media &&
                  productDetail.media.map((image) => {
                    return (
                      <SwiperSlide key={image.id}>
                        {isMobile ? (
                          <div
                            className="productDetailSwiper"
                            onClick={() => setModal(true)}
                          >
                            <img src={image.main} alt="product imgq" />
                          </div>
                        ) : (
                          <div
                            className="productDetailSwiper"
                            onClick={() => setModal(true)}
                          >
                            <img src={image.main} alt="product imgq" />
                          </div>
                        )}
                      </SwiperSlide>
                    );
                  })}
              </Swiper>
              <Swiper
                onSwiper={setThumbsSwiper}
                spaceBetween={10}
                slidesPerView={10}
                watchSlidesProgress={true}
                modules={[Navigation, Thumbs, Pagination]}
                className="mySwiper "
              >
                <SwiperSlide>
                  <div className="productDetailSwiper">
                    <img src={productDetail.title_image} alt="product img" />
                  </div>
                </SwiperSlide>
                {productDetail.media &&
                  productDetail.media.map((image) => {
                    return (
                      <SwiperSlide key={image.id}>
                        <div className="productDetailSwiper">
                          <img src={image.main} alt="product imgq" />
                        </div>
                      </SwiperSlide>
                    );
                  })}
              </Swiper>
            </div>

            <>
              {modal ? (
                <div className="modalContainer">
                  <div
                    className="modalClose"
                    onClick={() => {
                      setModal(false);
                      setThumbsSwiperPopup(null);
                    }}
                  >
                    <Cross />
                    {modal}
                  </div>
                  <div className="modalContent">
                    <Swiper
                      style={{
                        "--swiper-navigation-color": "#fff",
                        "--swiper-pagination-color": "#fff",
                      }}
                      slidesPerView={1}
                      spaceBetween={10}
                      navigation={true}
                      thumbs={{ swiper: thumbsSwiperPopup }}
                      modules={[Navigation, Thumbs, Pagination, A11y]}
                      className="mySwiper2"
                      pagination={{ clickable: true }}
                      breakpoints={{
                        320: {
                          slidesPerView: 1,
                        },
                      }}
                    >
                      <SwiperSlide>
                        <div
                          className="productDetailSwiper"
                          onClick={() => setModal(true)}
                        >
                          <img
                            src={productDetail.title_image}
                            alt="product img"
                          />
                        </div>
                      </SwiperSlide>
                      {productDetail.media &&
                        productDetail.media.map((image) => {
                          return (
                            <SwiperSlide key={image.id}>
                              <div className="productDetailSwiper">
                                <img src={image.main} alt="product imgq" />
                              </div>
                            </SwiperSlide>
                          );
                        })}
                    </Swiper>
                    <Swiper
                      onSwiper={setThumbsSwiperPopup}
                      spaceBetween={10}
                      slidesPerView={4}
                      watchSlidesProgress={true}
                      modules={[Navigation, Thumbs, Pagination]}
                      className="mySwiper detailsNav"
                    >
                      <SwiperSlide>
                        <div className="productDetailSwiper">
                          <img
                            src={productDetail.title_image}
                            alt="product img"
                          />
                        </div>
                      </SwiperSlide>
                      {productDetail.media &&
                        productDetail.media.map((image) => {
                          return (
                            <SwiperSlide key={image.id}>
                              <div className="productDetailSwiper">
                                <img src={image.main} alt="product imgq" />
                              </div>
                            </SwiperSlide>
                          );
                        })}
                    </Swiper>
                  </div>
                </div>
              ) : (
                false
              )}
            </>
          </div>
          <div className="xl-6 lg-6 md-6 sm-12">
            <div className="productRight">
              <div className="productRightTop">
                <Link to={`/products?brands=${productDetail.brand?.alias}`}>
                  {productDetail.brand?.title}
                </Link>
                <p>
                  {_t("title.product.code")} <span>{productDetail.sku}</span>
                </p>
              </div>
              <h2>{productDetail.title}</h2>
              <div className="productRightPrice">
                {productDetail.sale_price != 0 &&
                productDetail.sale_price != productDetail.price ? (
                  <p>
                    {productDetail.sale_price}
                    <Manat />
                  </p>
                ) : (
                  ""
                )}
                {productDetail.sale_price != 0 &&
                productDetail.sale_price != productDetail.price ? (
                  <span>
                    {productDetail.price}
                    <Manat />
                  </span>
                ) : (
                  <p>
                    {productDetail.price}
                    <Manat />
                  </p>
                )}
              </div>
              {/* <div className="productChoose">
                <div>
                  <div className="title">Məhsulun rəngini seçin</div>
                  <div className="productColor">
                    <label htmlFor="" className="filterRadio">
                      <span className="tooltype">green</span>
                      <input type="radio" name="radio" id="" />
                      <span className="checkmark"></span>
                    </label>
                    <label htmlFor="" className="filterRadio">
                      <span className="tooltype">green</span>
                      <input type="radio" name="radio" id="" />
                      <span className="checkmark"></span>
                    </label>
                    <label htmlFor="" className="filterRadio">
                      <span className="tooltype">green</span>
                      <input type="radio" name="radio" id="" />
                      <span className="checkmark"></span>
                    </label>
                  </div>
                </div>
                <div className="productTypeParent">
                  <div className="title titleType">Məhsulun rəngini seçin</div>
                  <div className="productType">
                    <Select
                      classNamePrefix="filter"
                      options={options}
                      components={{ IndicatorSeparator: () => null }}
                      defaultValue={{
                        label: (
                          <div>Burada sizin məhsulun çeşidi yazılacaq</div>
                        ),
                      }}
                      styles={customStyles}
                    />
                  </div>
                </div>
              </div> */}
              {/* <div className="title addToCartTitle">
                Məhsulun qiymətini seçin :
              </div> */}
              {outOfStock === 1 ? (
                <span className="outStok">{_t("forms.out_of_stock")}</span>
              ) : null}
              <div className="addToCartSection">
                <div className="quantity">
                  {quantites.map((qty) => (
                    <QuantityPicker
                      key={qty}
                      value={count}
                      min={0}
                      max={9999}
                      onChange={(newValue) => {
                        setCount(newValue);
                      }}
                    />
                  ))}
                </div>

                {outOfStock === 0 ? (
                  <button
                    className={buttonClass}
                    onClick={(e) => {
                      setButtonClass("addToCard green-button");
                      console.log(productDetail);
                      dispatch(addToCart({ ...productDetail, count }));
                    }}
                  >
                    <Cart />
                    {productDetail.in_basket ||
                    buttonClass == "addToCard green-button"
                      ? _t("buttons.already_added_basket")
                      : _t("buttons.add_to_basket")}
                  </button>
                ) : (
                  <button
                    className={buttonClass}
                    onClick={(e) => {
                      setButtonClass("addToCard green-button");
                      console.log(productDetail);
                      dispatch(addToCart({ ...productDetail, count }));
                    }}
                    disabled
                  >
                    <Cart />
                    {productDetail.in_basket ||
                    buttonClass == "addToCard green-button"
                      ? _t("buttons.already_added_basket")
                      : _t("buttons.add_to_basket")}
                  </button>
                )}
              </div>

              {token ? (
                <div className="buttonSection">
                  <div className="like">
                    <label className="heart">
                      <input
                        onClick={(e) => addTosWishlist(e, productDetail.id)}
                        className="like-button"
                        checked={productDetail.is_wished ? "checked" : null}
                        type="checkbox"
                      />
                      <Heart />

                      <span>{_t("buttons.add.to.wishlist")}</span>
                    </label>
                  </div>

                  <button
                    className="swap"
                    onClick={(e) => addToCompare(e, productDetail.id)}
                  >
                    <Swap />
                    {_t("buttons.add.to.compare")}
                  </button>
                </div>
              ) : null}
              <div className="shareSection">
                <div className="share">
                  <CopyToClipboard
                    onCopy={() => {
                      Swal.fire({
                        position: "center",
                        icon: "success",
                        title: _t("message.copied"),
                        showConfirmButton: false,
                        timer: 1000,
                      });
                    }}
                    text={window.location.href}
                  >
                    <a href="#">
                      <Share />
                    </a>
                  </CopyToClipboard>
                  <FacebookProvider appId="348228100426228">
                    <FbShare href={window.location.href}>
                      {({ handleClick, loading }) => (
                        <a href="#">
                          <Fb
                            type="button"
                            disabled={loading}
                            onClick={handleClick}
                          />
                        </a>
                      )}
                    </FbShare>
                  </FacebookProvider>
                  <ShareLink link={window.location.href}>
                    {(link) => (
                      <a href={link} target="_blank">
                        <Tw />
                      </a>
                    )}
                  </ShareLink>

                  <a
                    target="_blank"
                    href={`whatsapp://send?text=Keçidə daxil ol https://toolino.com/products/${props.match.params.subcategory}/${props.match.params.alias}`}
                  >
                    <Whatsapp />
                  </a>
                </div>
                <div className="rating">
                  <Rating
                    initialValue={productDetail.rating}
                    fillColor={"#FF0852"}
                    readonly={true}
                    allowHalfIcon={true}
                    iconsCount={5}
                    size={20}
                    ratingValue={
                      productDetail.rating * 20
                    } /* Available Props */
                  />

                  <span>({productDetail.ratingCount})</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Tabs>
          <div className="productDetailTab">
            <TabList>
              <Tab>{_t("site_content.product.parameters")}</Tab>
              <Tab>{_t("site_content.product.information")}</Tab>
              <Tab>{_t("site_content.product.comments")}</Tab>
            </TabList>
          </div>

          <TabPanel>
            <div className="productDetailIndicators">
              <div className="productDetailIndicatorsChild productDetailIndicatorsChildLeft ">
                <p>
                  {_t("forms.brand")}:
                  <Link to={`/products?brand=${productDetail.brand?.alias}`}>
                    {productDetail.brand?.title}
                  </Link>
                </p>
                <p>
                  {_t("forms.category")}:
                  <Link
                    to={`/products/category/${productDetail.category?.alias}`}
                  >
                    {productDetail.category?.title}
                  </Link>
                </p>
                {Object.keys(productDetail.attributes).map((key) => {
                  return (
                    <>
                      {productDetail.attributes[key] != "-" ? (
                        <p>
                          {_t(`attributes.${key}`)}:
                          <Link
                            to={`/products?${key}=${productDetail.attributes[key]}`}
                          >
                            {productDetail.attributes[key]}
                          </Link>
                        </p>
                      ) : null}
                    </>
                  );
                })}
              </div>
              {/* <div className="productDetailIndicatorsChild">

              </div> */}
            </div>
          </TabPanel>
          <TabPanel>
            <div className="productDetailInfo">
              <h5>{_t("title.product.info")}</h5>
              <div
                dangerouslySetInnerHTML={{
                  __html: productDetail.content,
                }}
              ></div>
            </div>
          </TabPanel>
          <TabPanel>
            <FacebookProvider appId="590828725764051">
              <Comments autoLogAppEvents={1} href={window.location.href} />
            </FacebookProvider>

            {/* <DiscussionEmbed
              shortname="toolino"
              config={{
                url: `{products/getProductInfoByUrl/${props.match.params.subcategory}/${props.match.params.alias}`,
                identifier: `{products/getProductInfoByUrl/${props.match.params.subcategory}/${props.match.params.id}`,
                title: productDetail.title_image,
                sso: {
                  name: "toolino",
                  url: "https://toolino.com",
                  logout: "https://toolino.com/login",

                  width: "800",
                  height: "400",
                },
              }}
            /> */}
            <Disqus.DiscussionEmbed
              shortname={disqusShortname}
              config={{
                url: `{products/getProductInfoByUrl/${props.match.params.subcategory}/${props.match.params.alias}`,
                identifier: `{products/getProductInfoByUrl/${props.match.params.subcategory}/${props.match.params.id}`,
                title: productDetail.title_image,
                sso: {
                  name: "toolino",
                  url: "https://toolino.com",
                  logout: "https://toolino.com/login",

                  width: "800",
                  height: "400",
                },
              }}
            />
          </TabPanel>
        </Tabs>

        <div className="productSlide">
          <div className="container">
            <div className="productSlideHeader">
              {<h3 className="title">{_t("forms.similar_products")}</h3>}
              <div className="customNav">
                <button className="custom_prev custom_prev1">
                  <Arrow />
                </button>
                <button className="custom_next custom_next1">
                  <Arrow />
                </button>
              </div>
            </div>
            <div className="productSlides">
              <Swiper
                navigation={{
                  nextEl: ".custom_next1",
                  prevEl: ".custom_prev1",
                }}
                modules={[Pagination, Navigation]}
                loop={true}
                pagination={false}
                className="myswiper"
                slidesPerView={3}
                spaceBetween={16}
                breakpoints={{
                  320: {
                    slidesPerView: 1.4,
                  },
                  767: {
                    slidesPerView: 1.8,
                  },
                  992: {
                    slidesPerView: 2.3,
                  },
                  1200: {
                    slidesPerView: 3,
                  },
                }}
              >
                {similars.map((similar) => {
                  return (
                    <SwiperSlide key={`similars_${similar.id}`}>
                      <ProductCard
                        category={category}
                        product={similar}
                        onSelect={persistScrollPosition}
                      />
                    </SwiperSlide>
                  );
                })}
              </Swiper>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ProductDetail;
