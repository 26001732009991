import React, { useEffect, useState } from "react";
import "./About.scss";
import { Link } from "react-router-dom";
import { ReactComponent as More } from "../../../assets/arrowRight.svg";
import ApiService from "../../../api/axios";
import { _t } from "../../../helpers/helpers";
import {
  LazyLoadImage,
  trackWindowScroll,
} from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

function About({ scrollPosition }) {
  const [about, setAbout] = useState({
    title_image: {},
  });

  useEffect(() => {
    ApiService.get("index/about").then((resp) => {
      setAbout(resp.data.content);
      console.log(resp.data.content);
    });
  }, []);
  return (
    <div className="About">
      <div className="container">
        <div className="row">
          <div className="xl-4 lg-4 md-4 sm-12">
            <div className="aboutContent">
              <h6>{about.title}</h6>
              <p
                dangerouslySetInnerHTML={{
                  __html: about.description,
                }}
              ></p>
              <Link to="/about" className="more">
                {_t("button.read_more")}
                <More />
              </Link>
            </div>
          </div>
          <div className="xl-8 lg-8 md-8 sm-12">
            <LazyLoadImage
              src={about.title_image}
              className="aboutImg"
              alt="Aboutİmg"
              effect="blur"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default trackWindowScroll(About);
