import React, { useState } from "react";
import InputMask from "react-input-mask";
import ApiService from "../../../api/axios";
import { ReactComponent as Lock } from "../../../assets/lock.svg";
import { _t } from "../../../helpers/helpers";
import "../Authentification.scss";

const Forget = (props) => {
  const [phone, setPhone] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    if (phone !== 0) {
      ApiService.post("/auth/getResetOTP", { phone }).then((resp) => {
        if (resp.data.success) {
          props.history.push("/reset-password");
        }
      });
    }
  };

  return (
    <div className="auth login">
      <div className="container">
        {/* <Breadcrumb
          link="/login"
          linkName="login"
          className="active"
          secondLink="/forget-password/"
          secondLinkName="Şifrəni unutmuşam"
        /> */}
        <div className="row">
          <div className="xl-3 lg-3 md-12 sm-12"></div>
          <div className="xl-6 lg-6 md-12 sm-12">
            <div className="authContainer">
              <div className="title">{_t("buttons_forgot_password")}</div>
              <form onSubmit={handleSubmit} className="authContainerBody">
                <div className="input-group phone">
                  <InputMask
                    mask="+\9\9\4999999999"
                    maskChar=" "
                    name="phone"
                    placeholder={_t("table.header_phone")}
                    onChange={(e) => setPhone(e.target.value)}
                  />
                </div>
                <button className="blackButton">
                  <Lock />
                  {_t("buttons.send")}
                </button>
                <span className="sendToEmail">
                  {_t("conifrm.code.content")}
                </span>
              </form>
            </div>
          </div>
          <div className="xl-3 lg-3 md-12 sm-12"></div>
        </div>
      </div>
    </div>
  );
};

export default Forget;
